export const getNeworkId = (id: string | number | undefined) => {
  let networkId = "ceb45d12-702f-403c-941d-35b4ded3a281";
  switch (id) {
    case 8453:
      networkId = "ceb45d12-702f-403c-941d-35b4ded3a281";
      break;
    case 56:
      networkId = "ceb45d12-702f-403c-941d-35b4ded3a282";
      break;
    default:
      networkId = "ceb45d12-702f-403c-941d-35b4ded3a281";
  }
  return networkId;
};

export const getNeworkInfo = (id: string | number | undefined) => {
  let networkInfo = {
    name: "",
    chainName: "",
    chainId: 0,
    nativeCurrency: {
      name: "",
      decimals: 0,
      symbol: "",
    },
    rpcUrls: [""],
  };
  switch (id) {
    case "ceb45d12-702f-403c-941d-35b4ded3a282":
      networkInfo = {
        name: "BNB",
        chainName: "BNB Chain",
        chainId: 56,
        nativeCurrency: {
          name: "BNB",
          decimals: 18,
          symbol: "BNB",
        },
        rpcUrls: ["https://bsc-dataseed.binance.org/"],
      };
      break;
    case "ceb45d12-702f-403c-941d-35b4ded3a281":
      networkInfo = {
        name: "BASE",
        chainName: "Base Mainnet",
        chainId: 8453,
        nativeCurrency: {
          name: "ETH",
          decimals: 18,
          symbol: "ETH",
        },
        rpcUrls: ["https://mainnet.base.org"],
      };
      break;
    default:
      networkInfo = {
        name: "BASE",
        chainName: "Base Mainnet",
        chainId: 8453,
        nativeCurrency: {
          name: "ETH",
          decimals: 18,
          symbol: "ETH",
        },
        rpcUrls: ["https://mainnet.base.org"],
      };
  }
  return networkInfo;
};

export const getPromotionContract = (id: string | number | undefined) => {
  let contractAddress: `0x${string}` =
    "0xD39a86fe7452D57ff7E034A57293bC589011C6b8";
  switch (id) {
    case 8453:
      contractAddress = "0xD39a86fe7452D57ff7E034A57293bC589011C6b8";
      break;
    case 56:
      contractAddress = "0xd7Ae89f0a874FB6EABBB55c517f740bCa3E64284";
      break;
    default:
      contractAddress = "0xD39a86fe7452D57ff7E034A57293bC589011C6b8";
  }
  
  return contractAddress;
};
