import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import Token from "features/token/Token";
import { FunctionComponent } from "react";
import { PromotionTokenTypes } from "types";
import DottedText from "ui/components/DottedText/DottedText";

interface ManageCampaignModalProps {
  isOpen: boolean;
  onClose(): void;
  campaignData: PromotionTokenTypes;
}

const ManageCampaignModal: FunctionComponent<ManageCampaignModalProps> = ({
  isOpen,
  onClose,
  campaignData,
}) => {
  const decimalsPowered = 10 ** (campaignData?.token_info?.decimals ?? 0);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={{ base: "2xl", lg: "3xl" }}
    >
      <ModalOverlay />
      <ModalContent p={{ base: "3em !important", lg: "2em !important" }}>
        <ModalHeader mb={"1em"}>Manage Campagin</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack gap={"2em"}>
            <Token
              img={campaignData?.token_info.img_url ?? ""}
              name={campaignData?.token_info.name ?? ""}
              shortName={campaignData?.token_info.short_name ?? ""}
            />

            <DottedText
              leftText={"Total daily rewards"}
              rightText={
                campaignData?.total_daily_rewards / decimalsPowered +
                " " +
                campaignData?.token_info.short_name
              }
            />

            <DottedText
              leftText={"Total campaign rewards"}
              rightText={
                campaignData?.total_campaign_rewards / decimalsPowered +
                " " +
                campaignData?.token_info.short_name
              }
            />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageCampaignModal;
