import {
  Box,
  Center,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import {
  useFetchTokens,
  useSetUpReferralsTokens,
} from "features/setUpReferrals/api/client";
import { FunctionComponent, useState } from "react";
import Token from "features/token/Token";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormikContextType } from "formik";
import { CreateCampaignFormType } from "features/setUpReferrals/api/type";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { useNetwork } from "wagmi";
import { getNeworkId } from "helpers/networkHelper";
interface CreateFormChooseTokenProps {
  formik: FormikContextType<CreateCampaignFormType>;
}

const CreateFormChooseToken: FunctionComponent<CreateFormChooseTokenProps> = ({
  formik,
}) => {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const networkId = getNeworkId(chain?.id);

  const { data: response } = useSetUpReferralsTokens({
    pageNumber: 1,
    promotionTokenNetwork: networkId,
    user: address?.toLocaleLowerCase(),
    value: false,
  });
  const campainTokens: string[] = [];
  // eslint-disable-next-line array-callback-return
  response.results.map((item) => {
    if (item.status !== "ended") {
      campainTokens.push(item.token);
    }
  });

  const [searchInput, setSearchInput] = useState<string>();
  const {
    data: tokensData,
    isLoading: isTokensDataLoading,
    ...infiniteScrollProps
  } = useFetchTokens(searchInput, networkId);

  const { colorMode } = useColorMode();

  return (
    <Box>
      <Heading fontSize={"xxl"}>Create Campaign</Heading>
      <Text fontFamily={"main"} opacity={"0.5"} mb={"2em"}>
        Choose a token you'd like people to promote.
      </Text>
      <Heading size={"sm"} mb={"1em"}>
        CRYPTO TOKENS
      </Heading>
      <InputGroup mb={"1em"}>
        <InputLeftElement ml={"0.75em"}>
          <FiSearch fontSize={"1.25em"} />
        </InputLeftElement>
        <Input
          type="text"
          pl={"2.75em"}
          placeholder={"Search buy token or contract address"}
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
        />
      </InputGroup>

      <VStack
        h={"20em"}
        overflowY={"auto"}
        id={"scrollableDiv"}
        spacing={"2rem"}
        alignItems={"stretch"}
      >
        {isTokensDataLoading && (
          <Center h={"100%"} w={"100%"}>
            <Spinner size={"xl"} />
          </Center>
        )}
        <InfiniteScroll
          next={() => {
            infiniteScrollProps.fetchNextPage();
          }}
          hasMore={infiniteScrollProps.hasNextPage}
          loader={
            <Center w={"100%"}>
              <Spinner size="xl" />
            </Center>
          }
          dataLength={tokensData?.pages.length ?? 0}
          scrollableTarget="scrollableDiv"
        >
          {tokensData?.pages
            .flatMap((page) => page.results)
            .map((token) => {
              return (
                <Box
                  w={"100%"}
                  cursor={"pointer"}
                  mb={"1em"}
                  transition={"all 0.2s ease-in"}
                  _hover={{
                    bg:
                      colorMode === "light"
                        ? "rgba(0, 0, 0, 0.1)"
                        : "rgba(255, 255,255, 0.1)",
                  }}
                  p={"0.5em"}
                  px={"0.5em"}
                  borderRadius={"0.25em"}
                  onClick={() => {
                    if (campainTokens.includes(token.token_contract)) {
                      toast.error("You have already created this company");
                    } else {
                      formik.setFieldValue("token", token);
                    }
                  }}
                >
                  <Token
                    img={token.img_url}
                    name={token.name}
                    shortName={token.short_name}
                  />
                </Box>
              );
            })}
        </InfiniteScroll>
      </VStack>
    </Box>
  );
};

export default CreateFormChooseToken;
