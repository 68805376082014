import { FunctionComponent } from "react";

interface MultistepProps {
  children: React.ReactNode[];
  currentStep: number;
}

const Multistep: FunctionComponent<MultistepProps> = ({
  children,
  currentStep,
}) => {
  return <>{children.at(currentStep)}</>;
};

export default Multistep;
