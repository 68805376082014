import { Box, Button, StyleProps, Text } from "@chakra-ui/react";
import { useGetTokens } from "features/rewardsPage/api/client";
import Token from "features/token/Token";
import { FunctionComponent, useMemo, useState } from "react";
import Pagination from "ui/components/Pagination";
import TableComponent from "ui/components/Table";
import { Link as ReactRouterLink } from "react-router-dom";
import { useAccount, useNetwork } from "wagmi";
import RewardsButton from "./RewardsButton";
import {
  useToAmount,
  useToFarmHash,
  useToClaimed,
} from "services/contracts/contractHooks/referralCampaignContract/managedReferralCampaignHooks";
import CampaignStats from "features/setUpReferrals/components/CampaignStats";
import { getNeworkId } from "helpers/networkHelper";

const headers = [
  {
    name: "referredToken",
    title: "Referred token",
    columnStyle: { textAlign: "start" } as StyleProps,
  },
  {
    name: "rewards_claimed",
    title: "Earned Rewards",
  },
  {
    name: "rewards",
    title: "Rewards to Claim",
  },
  {
    name: "start_time",
    title: "Start date",
  },
  {
    name: "end_time",
    title: "End date",
  },
  {
    name: "button",
    title: "",
  },
];

interface RewardsTableProps {}

const RewardsTable: FunctionComponent<RewardsTableProps> = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { address } = useAccount();
  const { chain } = useNetwork();
  const networkId = getNeworkId(chain?.id);

  let { data: response, refetch } = useGetTokens({
    pageNumber: currentPage,
    promotionTokenNetwork: networkId,
    referrer: address?.toLocaleLowerCase(),
  });

  const RewadsItem = (props: any) => {
    const { data: farmHash } = useToFarmHash(
      props.item.promotion.user,
      props.item.promotion.token_info.token_contract,
      props.item.promotion.farm_id
    );
    const { data } = useToAmount(farmHash, address);
    const test = data || 0;
    const amount =
      parseFloat(test.toString()) /
      Math.pow(10, props.item.promotion.token_info.decimals);

    return (
      <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
        {amount.toFixed(4) + ` ${props.item.promotion.token_info.short_name}`}
      </Text>
    );
  };
  const ClaimedItem = (props: any) => {
    const { data: farmHash } = useToFarmHash(
      props.item.promotion.user,
      props.item.promotion.token_info.token_contract,
      props.item.promotion.farm_id
    );
    const { data } = useToClaimed(farmHash, address);
    const { data: claimAmountData } = useToAmount(farmHash, address);

    const claimAmout = data || 0;
    const claimedAmount = claimAmountData || 0;
    const amount =
      parseFloat(claimAmout.toString()) /
        Math.pow(10, props.item.promotion.token_info.decimals) +
      parseFloat(claimedAmount.toString()) /
        Math.pow(10, props.item.promotion.token_info.decimals);
    return (
      <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
        {amount.toFixed(4) + ` ${props.item.promotion.token_info.short_name}`}
      </Text>
    );
  };

  const rows = useMemo(() => {
    return response.results.map((item) => {
      return {
        rewards_claimed: <ClaimedItem item={item} />,
        rewards_blocked: (
          <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
            {item.rewards_blocked + ` ${item.promotion.token_info.short_name}`}
          </Text>
        ),
        rewards: <RewadsItem item={item} />,
        start_time: (
          <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
            {new Date(
              item.promotion.start_time ?? Date.now()
            ).toLocaleDateString("en-GB")}
          </Text>
        ),
        end_time: (
          <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
            {new Date(
              new Date(item.promotion.start_time ?? Date.now()).getTime() +
                Math.floor(
                  (item.promotion.total_campaign_rewards /
                    item.promotion.total_daily_rewards) *
                    24 *
                    60 *
                    60 *
                    1000
                )
            ).toLocaleDateString("en-GB")}
          </Text>
        ),
        referredToken: (
          <Token
            imgStyle={{
              w: "2.5em",
              h: "2.5em",
              borderRadius: "3.125em",
            }}
            nameStyle={{ fontSize: "0.875em", fontWeight: "600" }}
            shortNameStyle={{
              fontSize: "0.75em",
              fontWeight: "400",
              opacity: "0.5",
            }}
            img={item.promotion.token_info.img_url}
            name={item.promotion.token_info.name}
            shortName={item.promotion.token_info.short_name}
          />
        ),
        button: <RewardsButton promotion={item} refetch={refetch} />,
        dropDownContent: <CampaignStats campaignInfo={item.promotion} />,
      };
    });
  }, [response.results]);

  return (
    <>
      <Box mt={"2.5em"} overflowX="auto" whiteSpace="nowrap">
        <TableComponent
          headers={headers}
          rows={rows}
          walletConnectText="Connect your wallet to see earned rewards"
          emptyRowsHeader="No farming rewards yet"
          emptyRowsTitle={
            <>
              Receive rewards for invitations:
              <br />
              Invite others to start receiving your rewards!
            </>
          }
          necessaryConectWallet={true}
          emptyChildren={
            <Button as={ReactRouterLink} to={"/"}>
              Promote to Earn
            </Button>
          }
        />
        <Pagination
          containerStyle={{
            mt: "1.5em",
          }}
          count={response.count}
          currentPage={currentPage}
          handlePageChange={(pageNumber: number) => {
            setCurrentPage(pageNumber);
          }}
          maxPagesToShow={8}
        />
      </Box>
    </>
  );
};

export default RewardsTable;
