import { Text, StyleProps, Flex } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import TableComponent from "ui/components/Table";
import { usePromoteTokens } from "features/promotionalPage/api/client";
import Token from "features/token/Token";
import Pagination from "ui/components/Pagination";
import { useState } from "react";
import CampaignStats from "features/setUpReferrals/components/CampaignStats";
import { useNetwork } from "wagmi";
import { getNeworkId } from "helpers/networkHelper";

interface CompletedPromotionProps {}
const headers = [
  {
    name: "referredToken",
    title: "Referred token",
    columnStyle: { textAlign: "start" } as StyleProps,
  },
  {
    name: "total_daily_rewards",
    title: "Total daily rewards",
  },
  {
    name: "total_campaign_rewards",
    title: "Total campaign rewards",
  },
  {
    name: "start_time",
    title: "Start date",
  },
  {
    name: "end_time",
    title: "End date",
  },
  {
    name: "button",
    title: "",
  },
];

const CompletedPromotion: FunctionComponent<CompletedPromotionProps> = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { chain } = useNetwork();
  const networkId = getNeworkId(chain?.id);
  // TODO
  const { data: response } = usePromoteTokens({
    pageNumber: currentPage,
    promotionTokenNetwork: networkId,
    status: ["ended"],
  });
  const rows = response.results.map((item, index) => {
    return {
      referral_rate: (
        <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
          {item.referral_rate + "%"}
        </Text>
      ),
      total_daily_rewards: (
        <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
          {(item.total_daily_rewards / 10 ** item.token_info.decimals).toFixed(
            2
          ) + ` ${item.token_info.short_name}`}
        </Text>
      ),
      total_campaign_rewards: (
        <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
          {(
            item.total_campaign_rewards /
            10 ** item.token_info.decimals
          ).toFixed(2) + ` ${item.token_info.short_name}`}
        </Text>
      ),
      referredToken: (
        <Token
          imgStyle={{
            w: "2.5em",
            h: "2.5em",
            borderRadius: "3.125em",
          }}
          nameStyle={{ fontSize: "0.875em", fontWeight: "600" }}
          shortNameStyle={{
            fontSize: "0.75em",
            fontWeight: "400",
            opacity: "0.5",
          }}
          img={item.token_info.img_url}
          name={item.token_info.name}
          shortName={item.token_info.short_name}
        />
      ),
      start_time: (
        <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
          {new Date(item.start_time ?? Date.now()).toLocaleDateString("en-GB")}
        </Text>
      ),
      end_time: (
        <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
          {new Date(
            new Date(item.start_time ?? Date.now()).getTime() +
              Math.floor(
                (item.total_campaign_rewards / item.total_daily_rewards) *
                  24 *
                  60 *
                  60 *
                  1000
              ) -
              86400000
          ).toLocaleDateString("en-GB")}
        </Text>
      ),
      dropDownContent: <CampaignStats campaignInfo={item} />,
    };
  });
  return (
    <>
      <Text
        fontFamily="secondary"
        mt={"2.5em"}
        fontSize={{ base: "1em", sm: "1.25em" }}
        fontWeight={"600"}
        mb={"1.25em"}
        textTransform={"uppercase"}
      >
        Completed Campaigns
      </Text>
      <Flex overflowX="auto" whiteSpace="nowrap">
        <TableComponent
          headers={headers}
          rows={rows}
          emptyRowsTitle="Sorry, but there are no tokens yet"
          emptyRowsHeader="Please check later."
        />
        <Pagination
          containerStyle={{
            mt: "1.5em",
          }}
          count={response.count}
          currentPage={currentPage}
          handlePageChange={(pageNumber: number) => {
            setCurrentPage(pageNumber);
          }}
          maxPagesToShow={8}
        />
      </Flex>
    </>
  );
};

export default CompletedPromotion;
