import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import { Box, ChakraProvider, ColorModeProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { theme } from "ui/theme/mainTheme";
import DefaultLayout from "ui/layouts/DefaultLayout";
import PromoteToEarnPage from "pages/PromoteToEarnPage";
import SetUpReferrals from "pages/SetUpReferrals";
import StakingPage from "pages/StakingPage";
import LeaderboardPage from "pages/LeaderboardPage";

import "react-toastify/dist/ReactToastify.css";

import { WagmiConfig, createConfig, configureChains } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";

import * as wagmiChains from "wagmi/chains";

import RewardsaPage from "pages/RewadsPage";
import BuyToEarnPage from "pages/BuyToEarnPage";
import ToastWrapper from "ui/components/ToastWrapper";

import NotFoundScreen from "pages/NotFound/NotFoundScreen";
import GenericModalContext from "ui/components/GenericModalContext";
import FAQ from "pages/FAQ";
const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        path: "",
        element: <PromoteToEarnPage />,
      },
      // {
      //   path: "stake",
      //   element: <StakingPage />,
      // },
      {
        path: "promote-to-earn",
        element: <>promote-to-earn</>,
      },
      {
        path: "set-up-referrals",
        element: <SetUpReferrals />,
      },
      {
        path: "leaderboard",
        element: <LeaderboardPage />,
      },
      {
        path: "rewards",
        element: <RewardsaPage />,
      },
      {
        path: "FAQ",
        element: <FAQ />,
      },
      {
        path: "buy-to-earn",
        element: <BuyToEarnPage />,
      },
      {
        path: "*",
        element: <NotFoundScreen />,
      },
    ],
  },
]);

const { chains, publicClient, webSocketPublicClient } = configureChains(
  (process.env.REACT_APP_SUPPORTED_CHAIN_IDS ?? "67").split(" ").map((item) => {
    return Object.entries(wagmiChains).find(([id, chain]) => {
      return chain.id === parseInt(item);
    })!![1];
  }),
  [
    publicProvider(),
    jsonRpcProvider({
      rpc: (chain) => ({
        http: process.env.REACT_APP_RPC_PROVIDER ?? "",
      }),
    }),
  ]
);

const config = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient,
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <WagmiConfig config={config}>
        <ColorModeProvider>
          <ChakraProvider theme={theme}>
            <GenericModalContext>
              <RouterProvider router={router} />
              <ToastWrapper />
            </GenericModalContext>
          </ChakraProvider>
        </ColorModeProvider>
      </WagmiConfig>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
