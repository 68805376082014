import { useQuery } from "@tanstack/react-query";
import { apiClient } from "services/api/apiClient";
import { RwardsTokenTypes } from "./type";

interface PaginationPropsType {
  pageNumber?: number;
  promotionTokenNetwork: string;
  referrer?: string;
}
export const useGetTokens = ({
  referrer,
  pageNumber,
  promotionTokenNetwork,
}: PaginationPropsType) => {
  return useQuery<RwardsTokenTypes>({
    queryKey: ["referraRewards", pageNumber, referrer, promotionTokenNetwork],
    queryFn: () => {
      return apiClient.get(
        `/referral-rewards?network_id=${promotionTokenNetwork}&page=${pageNumber}&referrer=${referrer}`
      );
    },
    initialData: {
      count: 0,
      next: null,
      previous: null,
      results: [
        {
          id: "",
          rewards: 0,
          users_number: 0,
          total_users: 0,
          rewards_claimed: 0,
          rewards_blocked: 0,
          promotion: {
            farm_id: 0,
            id: "",
            total_daily_rewards: 0,
            total_campaign_rewards: 0,
            referral_rate: 0,
            token: "",
            user: "0x",
            start_time: "",
            token_info: {
              name: "",
              short_name: "",
              img_url: "",
              decimals: 0,
              token_contract: "0x",
              network: "",
            },
          },
        },
      ],
    },
  });
};
