import { inputAnatomy } from "@chakra-ui/anatomy";
import { mode } from "@chakra-ui/theme-tools";

import {
  createMultiStyleConfigHelpers,
  StyleFunctionProps,
} from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const peakInput = definePartsStyle({
  field: {
    pl: "1.4em",
  },
});

const baseStyle = definePartsStyle({
  element: {
    h: "100%",
  },
  field: {
    border: "1px solid",
    borderColor: "#E0E0E0",
    fontWeight: 600,
    lineHeight: "1em",
    p: "1em",
    pt: "0.875em",
    fontFamily: "main",
    maxHeight: "none",
    height: "fit-content",
    fontSize: "sm",
    bg: "transparent",
    color: "#0D0D0D",

    _focus: {
      bg: "rgba(13, 13, 13, 0.05)",
    },

    _dark: {
      borderColor: "rgba(255, 255, 255, .2)",
      color: "#FFF",

      _focus: {
        bg: "rgba(255, 255, 255, 0.05)",
      },
    },
  },
});

export const inputTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { peakInput },
});
