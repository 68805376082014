import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Text,
  Box,
  useColorModeValue,
  StyleProps,
  Flex,
  Collapse,
} from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import { useAccount } from "wagmi";
import { HeadersTypes } from "types";
import WalletBox from "ui/layouts/DefaultLayout/components/WalletBox";

interface TableData {
  headers: HeadersTypes[];
  rows: any[];
  tableStyle?: StyleProps;
  headStyle?: StyleProps;
  rowStyle?: StyleProps;
  showNumber?: boolean;
  emptyRowsHeader?: string;
  emptyRowsTitle?: string | any;
  walletConnectText?: string;
  emptyChildren?: any;
  necessaryConectWallet?: boolean;
}

const TableComponent: FunctionComponent<TableData> = ({
  rows,
  headers,
  showNumber = true,
  emptyRowsHeader,
  emptyRowsTitle,
  walletConnectText = "Connect your wallet to see it",
  emptyChildren,
  necessaryConectWallet = false,
  ...props
}) => {
  const { isConnected } = useAccount();
  const emptyColor = useColorModeValue("#FAFAFA", "#252525");
  const isWalletConnect = necessaryConectWallet ? isConnected : true;
  const emptyHeader = isWalletConnect ? emptyRowsHeader : "Connect wallet";
  const emptyTitle = isWalletConnect ? emptyRowsTitle : walletConnectText;

  const showFallback = !isWalletConnect || rows.length === 0;
  const rowBg = useColorModeValue("#FAFAFA", "#252525");

  const [expandedRowIndex, setExpandedRowIndex] = useState<number>();

  const handleExpandRowClick = (index: number) => {
    if (expandedRowIndex === index) {
      setExpandedRowIndex(undefined);
    } else {
      setExpandedRowIndex(index);
    }
  };
  const bgThead = useColorModeValue("#F5F5F5", "#1A1A1A");
  return (
    <Box w={"100%"}>
      {!showFallback && (
        <Table
          w={"100% !important"}
          overflow={"hidden"}
          borderRadius={"0.25rem"}
          style={{
            borderCollapse: "separate",
            borderSpacing: "0px 0.125em",
          }}
          {...props.tableStyle}
        >
          <Thead
            borderBottomWidth={"0.125em"}
            fontFamily={"Syne, sans-serif"}
            bg={bgThead}
            textTransform={"uppercase"}
            fontSize={"0.75em"}
            {...props.headStyle}
          >
            <Tr borderBottomWidth={"0.125em"}>
              {showNumber && (
                <Td border={"0px"} opacity={0.5} fontSize={"inherit"}>
                  №
                </Td>
              )}
              {headers.map((item, index) => (
                <Td
                  border={"0px"}
                  fontSize={"inherit"}
                  key={index}
                  opacity={0.5}
                  textAlign={"end"}
                  {...item.columnStyle}
                >
                  {item.title}
                </Td>
              ))}
            </Tr>
          </Thead>

          {!showFallback && (
            <Tbody bg={rowBg} {...props.rowStyle}>
              {rows.map((row, index) => (
                <>
                  <Tr
                    key={index}
                    onClick={() => {
                      handleExpandRowClick(index);
                    }}
                    cursor={!!row.dropDownContent ? "pointer" : undefined}
                  >
                    {showNumber && (
                      <Td key={index + 1} fontSize={"0.875em"} border={"0px"}>
                        {index + 1}
                      </Td>
                    )}

                    {headers.map((item) => {
                      return (
                        <Td
                          border={"0px"}
                          textAlign={"end"}
                          {...item.rowColumnStyle}
                          {...props.rowStyle}
                        >
                          {row[item.name]}
                        </Td>
                      );
                    })}
                  </Tr>

                  {row.dropDownContent && (
                    <Tr p={0} key={index + "_expandable"}>
                      <Td colSpan={"100%" as any} p={0} border={"none"}>
                        <Collapse in={expandedRowIndex === index}>
                          <Box w={"100%"}> {row.dropDownContent} </Box>
                        </Collapse>
                      </Td>
                    </Tr>
                  )}
                </>
              ))}
            </Tbody>
          )}
        </Table>
      )}

      {showFallback && (
        <Flex
          mt={"1px"}
          alignItems={"center"}
          justifyContent={"center"}
          w={"100%"}
          flexDirection={"column"}
          bg={emptyColor}
          textAlign={"center"}
          padding={"2em"}
        >
          <Text
            fontFamily="secondary"
            fontSize={{ base: "1.5em", sm: "2em" }}
            maxW={"100%"}
            fontWeight={"600"}
            whiteSpace={"normal"}
            wordBreak={"break-word"}
          >
            {emptyHeader}
          </Text>
          {emptyTitle && (
            <Text
              mt={"1.5em"}
              mb={"1.5em"}
              fontSize={{ base: "0.825em", sm: "1em" }}
              fontWeight={"400"}
              opacity={0.5}
              fontFamily={"main"}
            >
              {emptyTitle}
            </Text>
          )}
          {!isWalletConnect && <WalletBox />}
          {isWalletConnect && emptyChildren}
        </Flex>
      )}
    </Box>
  );
};

export default TableComponent;
