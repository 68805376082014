import { Text, Flex, Box } from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import CampaignsTable from "features/setUpReferrals/components/CampaignsTable";
import CreateCampaignButton from "features/setUpReferrals/components/CreateCampaignButton";
import CompletedSalesTable from "features/promotionalPage/components/CompletedSalesTable";

interface SetUpReferralsProps {}

const SetUpReferrals: FunctionComponent<SetUpReferralsProps> = () => {
  const [value, setValue] = useState<boolean>(false);

  return (
    <>
      <Flex
        justifyContent={"space-between"}
        flexDirection={{ base: "column", sm: "row" }}
      >
        <Box>
          <Text
            fontFamily="secondary"
            fontSize={{ base: "2em", sm: "2.5em" }}
            fontWeight={"600"}
          >
            Set up Campaign
          </Text>
          <Text
            mt={"0.5em"}
            fontSize={"0.875em"}
            fontWeight={"400"}
            opacity={0.5}
            fontFamily={"main"}
          >
            Create your own campaign and recommend it to your referrals.
          </Text>
        </Box>
        <CreateCampaignButton setValue={setValue} value={value} />
      </Flex>
      <CampaignsTable value={value} />
      <CompletedSalesTable value={value} />
    </>
  );
};

export default SetUpReferrals;
