import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const defaultStyle = defineStyle((props) => ({
  borderWidth: "1px",
  borderStyle: "solid",

  borderColor: mode("#E0E0E0", "#1F1F1F")(props),
  opacity: "1",
}));

export const dividerTheme = defineStyleConfig({
  variants: { defaultStyle },
  defaultProps: {
    variant: "defaultStyle",
  },
});
