import { Box, StyleProps, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FunctionComponent } from "react";

type CountdownTimerProps = {
  endDate: Date;
  containerStyle?: StyleProps;
  textStyle?: StyleProps;
};

const CountdownTimer: FunctionComponent<CountdownTimerProps> = ({
  endDate,
  containerStyle,
  textStyle,
}) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const getTimeUntil = (deadline: any) => {
    const time =
      Date.parse(deadline.toString()) - Date.parse(new Date().toString());

    if (time < 0) {
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    } else {
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    }
  };

  useEffect(() => {
    setInterval(() => getTimeUntil(endDate), 1000);
    return () => getTimeUntil(endDate);
  }, [endDate]);

  return (
    <Box minW={"6.6em"} justifyContent={"center"} {...containerStyle}>
      <Text
        bg={"linear-gradient(270deg, #0AA7F5 0%, #3CE7FF 100%)"}
        backgroundClip={"text"}
        fontWeight={"600"}
        fontFamily={"main"}
        fontSize={"1.5em"}
        {...textStyle}
      >
        {hours.toString().length === 1 ? "0" + hours : hours}:
        {minutes.toString().length === 1 ? "0" + minutes : minutes}:
        {seconds.toString().length === 1 ? "0" + seconds : seconds}
      </Text>
    </Box>
  );
};
export default CountdownTimer;
