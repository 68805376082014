import { Button, ButtonProps, StyleProps } from "@chakra-ui/react";
import React, { FunctionComponent, useEffect } from "react";
import {
  useAllowance,
  useApprove,
} from "services/contracts/contractHooks/foreignTokenContract/managedForeignTokenHooks";
import { TOKEN_CONTRACT_ADDRESS } from "services/contracts/contractsData/tokenContract";

interface ApprovableTransactionProps {
  buttonProps?: ButtonProps;
  tokenContractAddress?: `0x${string}`;
  contractAddress: string;
  allowanceCondition(allowance: bigint): boolean;
  callback(): void;
  title: string | React.ReactNode;
}

const ApprovableTransaction: FunctionComponent<ApprovableTransactionProps> = ({
  buttonProps,
  contractAddress,
  allowanceCondition,
  title,
  callback,
  tokenContractAddress = TOKEN_CONTRACT_ADDRESS,
}) => {
  const { data: allowance, isLoading } = useAllowance(
    tokenContractAddress,
    contractAddress
  );
  const { write, transactionData } = useApprove(tokenContractAddress);
  
  return (
    <Button
      isLoading={transactionData.isLoading}
      {...buttonProps}
      onClick={() => {
        if (allowanceCondition(allowance ?? BigInt(0))) {
          callback();
        } else {
          write(contractAddress);
        }
      }}
      fontWeight={"500"}
    >
      {allowanceCondition(allowance ?? BigInt(0)) ? title : "Approve"}
    </Button>
  );
};

export default ApprovableTransaction;
