import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { validationSchema } from "features/setUpReferrals/api/form";
import { CreateCampaignFormType } from "features/setUpReferrals/api/type";
import Token from "features/token/Token";
import { useFormik } from "formik";
import { FunctionComponent, useEffect, useState } from "react";
import DottedText from "ui/components/DottedText/DottedText";
import Multistep from "ui/components/Multistep";
import { signMessage } from "viem/_types/accounts/utils/signMessage";
import CreateFormChooseToken from "../CreateFormChooseToken";
import CreateFormDepositRewards from "../CreateFormDepositRewards";
import CreateFormSubmit from "../CreateFormSubmit";
import CreateFormSuccess from "../CreateFormSuccess";
import { useCreateCampaign } from "services/contracts/contractHooks/referralCampaignContract/managedReferralCampaignHooks";
import { floatToBigInt } from "helpers/bigInt_number";
import { useAccount, useBalance } from "wagmi";
import { createPromotion, getNewPromotionId } from "./client/api";
interface CreateCampaignModalProps {
  isOpen: boolean;
  onClose(): void;
  setValue: any;
  value: any;
}

const CreateCampaignModal: FunctionComponent<CreateCampaignModalProps> = ({
  isOpen,
  onClose,
  setValue,
  value,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [data, setData] = useState<any>({});

  const { write: contactRequest } = useCreateCampaign({
    onTransactionSuccess: () => {
      setCurrentStep(3);
      createPromotion(data).then(() => {
        setValue(!value);
      });
    },
  });
  const { address } = useAccount();

  const formik = useFormik<CreateCampaignFormType>({
    onSubmit: (data) => {
      const totalAmount =
        Math.pow(10, foreignTokenBalanceData?.decimals!!) *
        data.tokensDistributedCampaign;

      const dailyRewards =
        parseFloat(totalAmount.toString()) /
        ((new Date(data.endDate).getTime() -
          new Date(data.startDate).getTime()) /
          86400000 +
          1);
      getNewPromotionId(
        address!!.toLowerCase(),
        data.token?.token_contract!!
      ).then((item: any) => {
        contactRequest(
          data.token?.token_contract!!,
          totalAmount.toFixed(0),
          dailyRewards.toFixed(0),
          new Date(data.startDate),
          new Date(data.endDate),
          item.id
        );
        setData({
          wallet: address?.toLowerCase(),
          token_contract: data.token?.token_contract!!,
          totalAmount: totalAmount.toFixed(0),
          dailyRewards: dailyRewards.toFixed(0),
          startDate: new Date(data.startDate),
          endDate: new Date(data.endDate),
          id: item.id,
        });
      });
    },
    initialValues: {
      startDate: "",
      endDate: "",
      token: undefined,
      tokensDistributedCampaign: 0,
    },
  });

  const { data: foreignTokenBalanceData, error } = useBalance({
    address: address,
    token: formik.values.token?.token_contract as `0x${string}`,
    watch: true,
  });

  useEffect(() => {
    setCurrentStep(0);
    formik.resetForm();
  }, [isOpen]);

  useEffect(() => {
    if (formik.values.token && currentStep <= 1) {
      setCurrentStep(1);
    } else {
      setCurrentStep(0);
    }
  }, [formik.values]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={{ base: "2xl", lg: "3xl" }}
    >
      <ModalOverlay />
      <ModalContent p={{ base: "3em !important", lg: "2em !important" }}>
        <ModalCloseButton />
        <ModalBody>
          <Multistep currentStep={currentStep}>
            <CreateFormChooseToken formik={formik} />
            <CreateFormDepositRewards
              formik={formik}
              onPropagate={() => {
                setCurrentStep(2);
              }}
            />
            <CreateFormSubmit formik={formik} />
            <CreateFormSuccess />
          </Multistep>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateCampaignModal;
