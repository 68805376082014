import {
  extendTheme,
  Modal,
  StyleFunctionProps,
  ThemeConfig,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import { Button } from "./buttonTheme";
import { dividerTheme } from "./dividerTheme";
import { headingTheme } from "./headingTheme";
import { inputTheme } from "./inputTheme";
import { linkTheme } from "./linkTheme";
import { sliderTheme } from "./sliderTheme";
import { textTheme } from "./textTheme";
import { modalTheme } from "./modalTheme";

const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
  disableTransitionOnChange: false,
};

export const theme = extendTheme({
  config,
  colors: {
    primary: {
      light: "#FFFFFF",
      dark: "#0D0D0D",
    },
    dark: {
      400: "#1A1A1A",
      500: "#0D0D0D",
    },
    gold: {
      100: "#FFECB3",
      200: "#FFDF7F",
      300: "#FFE275",
      400: "#FFD960",
      500: "#FFBA00",
      600: "#FFB000",
      700: "#FFA500",
      800: "#FF9800",
      900: "#FF8700",
    },
    yellowish: {
      400: "#EEE8A9",
    },
    blackish: {
      200: "#002637",
    },
  },
  fonts: {
    main: "Manrope, sans-serif",
    secondary: "Syne, sans-serif",
  },
  fontSizes: {
    xs: "0.75em",
    sm: "0.875em",

    md: "1em",
    lg: "1.25em",
    xl: "1.5em",
    xxl: "2em",
    "3xl": "2.5em",
  },
  lineHeights: {
    normal: "120%",
    tall: "150%",
  },
  radii: {
    none: "0",
    sm: "0.125em",
    base: "0.5em",
    lg: "1.5em",
    max: "50%",
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        backgroundSize: "cover",
        fontFamily: "Manrope",
        backgroundColor: mode("#F5F5F5", "#0D0D0D")(props),
        color: mode("#0D0D0D", "white")(props),
        transitionProperty: "background-color",
        transitionDuration: "0.5s",
        fontSize: { base: "12px", md: "16px", lg: "20px" },
      },
    }),
  },
  breakpoints: {
    sm: "744px",
    md: "1280px",
    lg: "1700px",
  },

  components: {
    Modal: modalTheme,
    Button: Button,
    Divider: dividerTheme,
    Link: linkTheme,
    Text: textTheme,
    Heading: headingTheme,
    Input: inputTheme,
    Slider: sliderTheme,
  },
});
