import {
  Box,
  Flex,
  Switch,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import { FunctionComponent } from "react";

interface ThemeSwitcherProps {}

const ThemeSwitcher: FunctionComponent<ThemeSwitcherProps> = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex
      h={"2em"}
      borderRadius={"1.1em"}
      border={
        colorMode === "dark"
          ? "1px solid rgba(255, 255, 255, 0.50)"
          : "1px solid #0D0D0D"
      }
      w={"4em"}
      boxSizing={"content-box"}
      position={"relative"}
      transition="all 200ms linear"
      transitionDuration="200ms"
      alignItems={"center"}
      justifyContent={"space-between"}
      onClick={toggleColorMode}
      cursor={"pointer"}
    >
      <Box ml={"0.5em"} zIndex={2}>
        <SunIcon height={"1.1em"} color={"white"} />
      </Box>
      <Box
        h={"2em"}
        w={"2em"}
        bg={useColorModeValue("#0D0D0D", "white")}
        borderRadius={"1em"}
        position={"absolute"}
        left={colorMode === "light" ? "0" : "calc(100% - 2em)"}
        transition="all 200ms linear"
        transitionDuration="200ms"
      ></Box>
      <Box zIndex={"1"} mr={"0.5em"}>
        <MoonIcon height={"1em"} color={"black"} />
      </Box>
    </Flex>
  );
};

export default ThemeSwitcher;
