import { defineStyleConfig } from "@chakra-ui/styled-system";
import { mode } from "@chakra-ui/theme-tools";

export const modalTheme = defineStyleConfig({
  baseStyle: (props) => ({
    overlay: {
      bg: "rgba(0,0,0,0.5)",
    },
    dialog: {
      padding: "3em",
      bg: mode("#fff", "#252525")(props),
    },
    closeButton: {
      top: "1.25em",
      right: "1.25em",
      color: mode("#0D0D0D80", "#fff")(props),
      opacity: 0.5,
    },
    header: {
      padding: "0px",
      fontSize: "2em",
      fontFamily: "secondary",
      fontWeight: "600",
    },
    body: {
      padding: "0px",
    },
    footer: {
      padding: "0px",
    },
  }),
});
