import { useColorMode } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { ToastContainer } from "react-toastify";

interface ToastWrapperProps {}

const ToastWrapper: FunctionComponent<ToastWrapperProps> = () => {
  const { colorMode } = useColorMode();

  return (
    <ToastContainer
      theme={colorMode}
      bodyStyle={{ fontFamily: "Syne" }}
      progressStyle={{
        background: "linear-gradient(270deg, #0AA7F5 0%, #3CE7FF 100%)",
      }}
    />
  );
};

export default ToastWrapper;
