import { defineStyleConfig } from "@chakra-ui/styled-system";
import { mode } from "@chakra-ui/theme-tools";

export const linkTheme = defineStyleConfig({
  baseStyle: (props) => ({
    color: mode("#0D0D0D", "#FFFFFF")(props),
    opacity: 0.5,
    fontFamily: "secondary",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    textTransform: "uppercase",
    background: "",
    transition: "background 0.5s",
    display: "flex",

    _hover: {
      opacity: 1,
      textDecoration: "none",
    },
  }),
  variants: {
    active: (props) => ({
      textTransform: "uppercase",
      background: mode(
        "none",
        "linear-gradient(270deg, #0AA7F5 0%, #3CE7FF 100%)"
      )(props),
      backgroundClip: mode("none", "text")(props),
      opacity: 1,
      transition: "0.5s",

      _hover: {},
    }),
  },
  sizes: {
    sm: {
      fontSize: "sm",
    },
    md: {
      fontSize: "md",
    },
    lg: {
      fontSize: "lg",
    },
  },
  defaultProps: {
    size: "sm",
  },
});
