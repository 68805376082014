import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const defaultStyle = defineStyle((props) => ({
  color: mode("#0D0D0D", "white")(props),
}));

const navbar = defineStyle((props) => ({
  color: mode("#0D0D0D", "white")(props),
  textTransform: "uppercase",
  opacity: 0.5,
}));

const error = defineStyle((props) => ({
  color: mode("red.300", "red.200")(props),
  h: "0",
}));

const gradient = defineStyle((props) => ({
  bg: mode(
    "transparent",
    "linear-gradient(270deg, #0AA7F5 0%, #3CE7FF 100%)"
  )(props),
  fontFamily: "main",
  backgroundClip: mode("", "text")(props),
  fontWeight: 700,
}));

export const textTheme = defineStyleConfig({
  baseStyle: {
    fontFamily: "secondary",
  },
  variants: { defaultStyle, navbar, gradient, error},
  sizes: {
    sm: {
      fontSize: "xs",
      lineHeight: "1em",
    },
    md: {
      fontSize: "sm",
      lineHeight: "1em",
    },

    lg: {
      fontSize: "md",
      lineHeight: "1em",
    },

    xl: {
      fontSize: "lg",
      lineHeight: "1em",
    },
  },
  defaultProps: {
    variant: "defaultStyle",
    size: "md",
  },
});
