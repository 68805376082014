import { waitForTransaction } from "@wagmi/core";
import { useState } from "react";
import { toast } from "react-toastify";
import { REFERRAL_CAMPAIGN_CONTRACT_ABI } from "services/contracts/contractsData/referralCampaignContract";
import {
  useContractRead,
  useContractWrite,
  useNetwork,
  useWaitForTransaction,
} from "wagmi";
import { WriteTransactionOptionsType } from "../common/types";
import { getPromotionContract } from "helpers/networkHelper";

export const useCreateCampaign = (options?: WriteTransactionOptionsType) => {
  const [transactionHash, setTransactionHash] = useState<`0x${string}`>();
  const { chain } = useNetwork();
  const contractAddress = getPromotionContract(chain?.id);
  const transactionHook = useWaitForTransaction({
    hash: transactionHash,
  });
  const rawHook = useContractWrite({
    address: contractAddress,
    abi: REFERRAL_CAMPAIGN_CONTRACT_ABI,
    functionName: "startReferralFarm",
    onSuccess: (data) => {
      setTransactionHash(data.hash);
      waitForTransaction({ hash: data.hash }).then(() => {
        options?.onTransactionSuccess?.();
      });
      toast.promise(waitForTransaction({ hash: data.hash }), {
        error: "Transaction failed, please try again later",
        pending: "Transaction pending",
        success: "Transaction successful",
      });
    },
    onError: (item) => {
      toast.error("Transaction failed, please try again later");
    },
  });

  return {
    ...rawHook,
    write: (
      token_address: string,
      rewardAmount: string,
      referredAmount: string, //day
      timeStart: Date,
      endDate: Date,
      id: string
    ) => {
      if (token_address) {
        rawHook.write({
          args: [
            token_address,
            rewardAmount,
            referredAmount,
            Math.ceil(timeStart.getTime() / 1000),
            Math.ceil(endDate.getTime() / 1000),
            id,
          ],
        });
      }
    },
    transactionData: transactionHook,
    blockActions: transactionHook.isLoading || rawHook.isLoading,
  };
};

export const useToFarmHash = (
  walletAddress: string,
  tokenAddress: `0x${string}`,
  farm_id: any
) => {
  const { chain } = useNetwork();
  const contractAddress = getPromotionContract(chain?.id);
  const farmHash = useContractRead({
    args: [walletAddress, tokenAddress, farm_id],
    address: contractAddress,
    abi: REFERRAL_CAMPAIGN_CONTRACT_ABI,
    functionName: "toFarmHash",
    watch: true,
  });

  return farmHash;
};

export const useToAmount = (has: any, address: any) => {
  const { chain } = useNetwork();
  const contractAddress = getPromotionContract(chain?.id);
  const farmHash = useContractRead({
    args: [has, address],
    address: contractAddress,
    abi: REFERRAL_CAMPAIGN_CONTRACT_ABI,
    functionName: "getAmount",
    watch: true,
  });
  return farmHash;
};

export const useToClaimed = (has: any, address: any) => {
  const { chain } = useNetwork();
  const contractAddress = getPromotionContract(chain?.id);
  const toClaimed = useContractRead({
    args: [has, address],
    address: contractAddress,
    abi: REFERRAL_CAMPAIGN_CONTRACT_ABI,
    functionName: "getClaimed",
    watch: true,
  });
  return toClaimed;
};

export const useHarvestToken = (hash: `0x${string}`) => {
  const { chain } = useNetwork();
  const contractAddress = getPromotionContract(chain?.id);
  const [transactionHash, setTransactionHash] = useState<`0x${string}`>();

  const transactionHook = useWaitForTransaction({
    hash: transactionHash,
  });

  const claimRerralRewards = useContractWrite({
    args: [hash],
    address: contractAddress,
    abi: REFERRAL_CAMPAIGN_CONTRACT_ABI,
    functionName: "claimRerralRewards",
    onSuccess: (data) => {
      setTransactionHash(data.hash);
      toast.promise(waitForTransaction({ hash: data.hash }), {
        error: "Transaction failed, please try again later",
        pending: "Transaction pending",
        success: "Transaction successful",
      });
    },
    onError: (error) => {
      toast.error("Transaction failed, please try again later");
    },
  });

  return {
    ...claimRerralRewards,
    transactionData: transactionHook,
    blockActions: transactionHook.isLoading || claimRerralRewards.isLoading,
  };
};
