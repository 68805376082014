import axios, { AxiosError } from "axios";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL + 'api/v1/',
});

apiClient.interceptors.response.use(
  ({ data }) => data,
  (error: AxiosError) => Promise.reject(error)
);
