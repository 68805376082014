import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import Token from "features/token/Token";
import {
  useAccount,
  useNetwork,
  useSignMessage,
  useSwitchNetwork,
} from "wagmi";
import account_placeholder from "assets/images/account_placeholder.svg";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import InfoTable from "features/buyToEarnPage/component/InfoTablet";
import { useGetPromote } from "features/buyToEarnPage/api/client";
import { toast } from "react-toastify";
import { addAReferral } from "features/buyToEarnPage/api/client";
import { useGetCheckReferral } from "./client/api";
import { getNeworkId, getNeworkInfo } from "helpers/networkHelper";
import WalletBox from "ui/layouts/DefaultLayout/components/WalletBox";

interface BuyToEarnPageProps {}

const BuyToEarnPage: FunctionComponent<BuyToEarnPageProps> = () => {
  const [showSign, setShowSign] = useState(false);
  const { chain } = useNetwork();
  const networkId = getNeworkId(chain?.id);

  const { switchNetwork } = useSwitchNetwork();

  const { isConnected, address } = useAccount();
  let url = new URL(window.location.href);
  let promoteId = url.searchParams.get("promoteId");
  let walletAddress = url.searchParams.get("walletAddress");
  const { data: response } = useGetPromote({ promoteId });

  const { data } = useGetCheckReferral(
    address?.toLowerCase(),
    walletAddress?.toLowerCase(),
    promoteId,
    showSign
  );

  const saleStartDate = new Date(response?.start_time ?? "");

  const saleEndDate = new Date(response?.time_end ?? "");

  const { signMessage } = useSignMessage({
    message: `
Sign this message in order to enroll for rewards.
You won't incur any Ether expenses for this.`,
    onSuccess() {
      handleClick().then(() => {
        setShowSign(true);
      });
    },
  });

  const handleClick = () => {
    return addAReferral({
      referral: address?.toLocaleLowerCase(),
      promoteId: promoteId?.toLocaleLowerCase(),
      referrer: walletAddress?.toLocaleLowerCase(),
    });
  };

  const onCopyClick = () => {
    if (
      walletAddress &&
      walletAddress.toLocaleLowerCase() !== address?.toLocaleLowerCase()
    ) {
      signMessage();
    } else {
      toast.error("You can invite yourself");
    }
  };

  const getSwapUrl = (token: any, chain: any) => {
    let url = "";
    switch (chain.id) {
      case 8453:
        url = `https://app.uniswap.org/swap?chain=base&outputCurrency=${token}`;
        break;
      case 56:
        url = `https://pancakeswap.finance/swap?outputCurrency=${token}&chain=bnb`;
        break;
      default:
        url = `https://app.uniswap.org/swap?chain=base&outputCurrency=${token}`;
    }
    return url;
  };

  const openBSCScan = () => {
    switch (chain?.id) {
      case 8453:
        window.open(`https://basescan.org/address/${walletAddress}`, "_blank");
        break;
      case 56:
        window.open(`https://bscscan.com/address/${walletAddress}`, "_blank");
        break;
      default:
        window.open(`https://basescan.org/address/${walletAddress}`, "_blank");
    }
    return url;
  };

  const changeNetwork = () => {
    const networkInfo = getNeworkInfo(response.network_id);
    switchNetwork?.(networkInfo.chainId);
  };
  const getTitle = () => {
    let title = "";
    switch (chain?.id) {
      case 8453:
        title = "Buy Tokens on Uniswap";
        break;
      case 56:
        title = "Buy Tokens on Pancakeswap";
        break;
      default:
        title = "Buy Tokens on Uniswap";
    }
    return title;
  };

  return (
    <>
      <Text
        fontFamily="secondary"
        fontSize={{ base: "2em", sm: "2.5em" }}
        fontWeight={"600"}
      >
        Buy tokens
      </Text>
      <Text mt={"0.5em"} fontSize={"0.875em"} fontWeight={"400"} opacity={0.5}>
        Your referral recommends buying these tokens. You need to purchase the
        tokens on Uniswap, and below is the button to get started!
      </Text>
      <Box
        bg={useColorModeValue("#F5F5F5", "#1A1A1A")}
        borderRadius={"0.25em"}
        padding={"2em"}
        mt={"2.5em"}
      >
        <Flex
          alignItems={"center"}
          gap={"2rem"}
          justifyContent={"space-between"}
          flexDirection={{ base: "column", sm: "row" }}
        >
          <Token
            containerStyle={{
              mb: "1.25em",
            }}
            imgStyle={{
              w: "2.5em",
              h: "2.5em",
              borderRadius: "3.125em",
            }}
            nameStyle={{ fontSize: "0.875em", fontWeight: "600" }}
            shortNameStyle={{
              fontSize: "0.75em",
              fontWeight: "400",
              opacity: "0.5",
            }}
            img={response.token_info.img_url}
            name={response.token_info.name}
            shortName={response.token_info.short_name}
          />

          <Flex gap={"2rem"}>
            <Text fontSize={"1rem"} opacity={0.6}>
              Start date: <b>{saleStartDate.toLocaleDateString("en-GB")}</b>
            </Text>

            <Text fontSize={"1rem"} opacity={0.6}>
              End date: <b>{saleEndDate.toLocaleDateString("en-GB")}</b>
            </Text>
          </Flex>
        </Flex>

        <InfoTable
          dailyRewards={
            response.total_daily_rewards / 10 ** response.token_info.decimals
          }
          campaignRewards={
            response.total_campaign_rewards / 10 ** response.token_info.decimals
          }
          token={response.token}
          shortName={response.token_info.short_name}
        />

        <Flex
          mt={"2em"}
          w={"100%"}
          justifyContent={"space-between"}
          flexDirection={{ base: "column", sm: "row" }}
          gap={{ base: "1em", sm: "0em" }}
        >
          <Flex onClick={openBSCScan} cursor={"pointer"}>
            <Center
              h={"2.5em"}
              w={"2.5em"}
              bg={useColorModeValue("#E0E0E0", "rgba(255, 255, 255, 0.20)")}
              borderRadius={"50%"}
            >
              {walletAddress && <Image src={account_placeholder} />}
            </Center>
            {walletAddress && (
              <Flex
                ml={"0.5em"}
                flexDirection={"column"}
                justifyContent={"space-between"}
              >
                <Text
                  textTransform={"uppercase"}
                  fontWeight={"500"}
                  opacity={"0.5"}
                  fontSize={"0.75em"}
                  fontFamily={"secondary"}
                >
                  Invited by
                </Text>
                <Text
                  textTransform={"uppercase"}
                  size={"0.875em"}
                  fontWeight={"500"}
                  fontFamily={"main"}
                  display={"flex"}
                >
                  {walletAddress.slice(0, 15) + "..." + walletAddress.slice(-4)}
                  <ArrowUpRightIcon
                    width={"1em"}
                    style={{
                      marginLeft: "0.5em",
                    }}
                  />
                </Text>
              </Flex>
            )}
          </Flex>
          {chain && networkId !== response.network_id && (
            <Button
              borderRadius={"4em"}
              w={"18em"}
              onClick={() => changeNetwork()}
              isDisabled={
                !isConnected ||
                !(
                  new Date() >= new Date(response.start_time) &&
                  new Date() <= new Date(response.time_end)
                )
              }
            >
              Chenge network
            </Button>
          )}
          {networkId === response.network_id &&
            data.length >= 1 &&
            data[0]?.promotion !== "" && (
              <Button
                as={"a"}
                href={getSwapUrl(response.token, chain)}
                target={"_blank"}
                borderRadius={"4em"}
                pl={"2em"}
                pr={"2em"}
                isDisabled={
                  !isConnected ||
                  !(
                    new Date() >= new Date(response.start_time) &&
                    new Date() <= new Date(response.time_end)
                  )
                }
              >
                {getTitle()}
              </Button>
            )}

          {networkId === response.network_id &&
            (data[0]?.promotion === "" ? true : data.length < 1) && (
              <Button
                borderRadius={"4em"}
                w={"18em"}
                isDisabled={
                  !isConnected ||
                  !(
                    new Date() >= new Date(response.start_time) &&
                    new Date() <= new Date(response.time_end)
                  )
                }
                onClick={() => onCopyClick()}
              >
                sign
              </Button>
            )}
          {!chain && <WalletBox />}
        </Flex>
      </Box>
    </>
  );
};

export default BuyToEarnPage;
