import { FunctionComponent } from "react";
import {
  Box,
  Flex,
  StyleProps,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

type DottedTextProps = {
  leftText: string;
  rightText: string;
  rightTextStyle?: StyleProps;
  leftTextStyle?: StyleProps;
};

const DottedText: FunctionComponent<DottedTextProps> = ({
  leftText,
  rightText,
  rightTextStyle,
  leftTextStyle,
}) => {
  return (
    <Flex w={"100%"}>
      <Text
        mr={"0.5em"}
        textTransform={"uppercase"}
        fontWeight={"500"}
        fontSize={"0.75em"}
        fontFamily={"secondary"}
        opacity={0.5}
        whiteSpace={"nowrap"}
        {...leftTextStyle}
      >
        {leftText}
      </Text>
      <Box
        w={"100%"}
        borderBottom={"2px dotted"}
        borderColor={useColorModeValue(
          "rgba(0,0,0,0.2)",
          "rgba(256,256,256,0.2)"
        )}
      />
      <Text
        ml={"0.5em"}
        whiteSpace={"nowrap"}
        textTransform={"uppercase"}
        fontSize={"0.875em"}
        fontFamily={"main"}
        fontWeight={"600"}
        {...rightTextStyle}
      >
        {rightText}
      </Text>
    </Flex>
  );
};

export default DottedText;
