import { useQuery } from "@tanstack/react-query";
import { apiClient } from "services/api/apiClient";
import { PromotionTokenTypes } from "types";

interface getPromoteType {
  promoteId: string | null;
}
export const useGetPromote = ({ promoteId }: getPromoteType) => {
  return useQuery<PromotionTokenTypes>({
    queryKey: ["buytoEarnTokens", promoteId],
    queryFn: () => {
      return apiClient.get(`/promotions/${promoteId}`);
    },
    initialData: {
      id: "",
      total_daily_rewards: 0,
      total_campaign_rewards: 0,
      referral_rate: 0,
      token: "",
      user: "",
      time_end: "",
      start_time: "",
      status: "",
      network_id: "",
      token_info: {
        name: "",
        short_name: "",
        img_url: "",
        decimals: 0,
        token_contract: "",
        network: "",
      },
    },
  });
};

interface addAReferralType {
  promoteId: string | undefined;
  referrer: string | undefined;
  referral: string | undefined;
}
export const addAReferral = ({
  promoteId,
  referrer,
  referral,
}: addAReferralType) => {
  return apiClient.post(`/referral/`, {
    promotion: promoteId,
    referrer,
    referral,
  });
};
