import { Box, Center, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { FunctionComponent } from "react";

interface InfoItemProps {
  number: string | number;
  firstTitle: string;
  secondTitle: string;
}

const InfoItem: FunctionComponent<InfoItemProps> = ({
  number,
  firstTitle,
  secondTitle,
}) => {
  return (
    <Center mt={"0.5em"}>
      <Center
        borderRadius={"99em"}
        w={"2em"}
        h={"2em"}
        borderWidth={"1px"}
        borderColor={useColorModeValue("#E0E0E0", "rgba(255, 255, 255, 0.20)")}
        fontSize={"1.25em"}
        fontWeight={500}
        textTransform={"uppercase"}
        fontFamily={"main"}
      >
        {number}
      </Center>
      <Box
        ml={"0.75em"}
        fontSize={"0.875em"}
        fontWeight={400}
        fontFamily={"main"}
        opacity={0.5}
      >
        <Text>{firstTitle}</Text>
        <Text>{secondTitle}</Text>
      </Box>
    </Center>
  );
};

export default InfoItem;
