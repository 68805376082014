import { Center, Text, Flex, Button } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { Link as ReactRouterLink } from "react-router-dom";

interface FAQItemProps {
  title: string;
  text: string;
}

const FAQItem: FunctionComponent<FAQItemProps> = ({ title, text }) => {
  return (
    <Flex w={"100%"} flexDirection={"column"}>
      <Text
        mt={"1.5em"}
        fontFamily="secondary"
        fontSize={"1.25em"}
        fontWeight={"600"}
      >
        {title}
      </Text>
      <Text mt={"0.5em"} fontSize={"1.125em"} fontWeight={"400"} opacity={0.5}>
        {"\u00A0\u00A0\u00A0\u00A0" + text}
      </Text>
    </Flex>
  );
};

export default FAQItem;
