import {
  Divider,
  Heading,
  Image,
  Text,
  useColorModeValue,
  Box,
  Flex,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import {
  TrophyIcon,
  RocketLaunchIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { PiHandCoins } from "react-icons/pi";
import { HiOutlineWrench } from "react-icons/hi2";
import logo_dark from "assets/images/logo.png";
import logo_dark_inline from "assets/images/logo_inline_dark.svg";
import logo_light_inline from "assets/images/logo_inline_light.svg";
import ThemeSwitcher from "./components/ThemeSwitcher";
import SocialMedia from "./components/SocialMedia";
import Link from "ui/components/Link";
import { Link as ReactRouterLink, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import WalletBox from "./components/WalletBox";
import ChangeNetworks from "./components/ChangeNetworks";
import ChangeNetworkModal from "ui/components/ChangeNetworkModal/ChangeNetworkModal";
import MobileMenu from "./components/MobileMenu/MobileMenu";
interface DefaultLayoutProps {
  children?: React.ReactNode | React.ReactNode[];
}

const DefaultLayout: FunctionComponent<DefaultLayoutProps> = ({ children }) => {
  const location = useLocation();

  return (
    <Flex
      minW={"100vw"}
      pt={"1.5em"}
      pl={{ base: "0", sm: "2em" }}
      gap={"2em"}
      minH={"100vh"}
    >
      <ChangeNetworkModal />

      <Box minW={"12.5em"} display={{ base: "none", sm: "block" }}>
        <Flex
          as={ReactRouterLink}
          to="/"
          alignItems={"center"}
          mb={"5em"}
          gap={"1rem"}
        >
          <Image src={logo_dark} w={"5em"}></Image>
          <Heading
            fontFamily={"main"}
            fontWeight={"900"}
            letterSpacing={"0.05em"}
          >
            PEAKDEFI
          </Heading>
        </Flex>

        {/* <Link
          as={ReactRouterLink}
          to="/stake"
          variant={location.pathname === "/stake" ? "active" : undefined}
          size={"md"}
          mb={"2.5em"}
          leftIcon={<CircleStackIcon />}
        >
          stake
        </Link> */}
        <Link
          as={ReactRouterLink}
          to="/"
          variant={location.pathname === "/" ? "active" : undefined}
          size={"md"}
          mb={"1.25em"}
          leftIcon={<RocketLaunchIcon />}
        >
          Promote to Earn
        </Link>
        {/* <Stack ml={"1.6em"} mb={"2.5em"} gap={"1.25em"}> */}
        <Link
          as={ReactRouterLink}
          to="/rewards"
          size={"md"}
          mb={"1.25em"}
          variant={location.pathname === "/rewards" ? "active" : ""}
          leftIcon={<PiHandCoins />}
        >
          Rewards
        </Link>
        <Link
          as={ReactRouterLink}
          to="/set-up-referrals"
          size={"md"}
          mb={"1.25em"}
          leftIcon={<HiOutlineWrench />}
          variant={
            location.pathname === "/set-up-referrals" ? "active" : undefined
          }
        >
          Set up Campaign
        </Link>
        <Link
          as={ReactRouterLink}
          to="/leaderboard"
          variant={location.pathname === "/leaderboard" ? "active" : undefined}
          size={"md"}
          mb={"1.25em"}
          leftIcon={<TrophyIcon />}
        >
          leaderboard
        </Link>
        {/* </Stack> */}

        <Divider />
        <Flex
          as={"a"}
          href={"https://launchpad.peakdefi.com/"}
          py={"1.25em"}
          justifyContent={"space-between"}
          alignItems={"center"}
          target="_blank"
        >
          <Image
            src={useColorModeValue(logo_dark_inline, logo_light_inline)}
            h={"1.7em"}
          />
          <ArrowUpRightIcon
            height={"1.1em"}
            color={useColorModeValue("#0D0D0D", "#FFFFFF")}
            opacity={"0.5"}
          />
        </Flex>
        <Divider />

        <Link
          as={ReactRouterLink}
          to="/FAQ"
          variant={location.pathname === "/FAQ" ? "active" : undefined}
          size={"md"}
          mt={"2.5em"}
          leftIcon={<QuestionMarkCircleIcon />}
        >
          FAQ
        </Link>

        <Flex justifyContent={"space-between"} py={"5em"} alignItems={"center"}>
          <Text variant={"navbar"} size={"md"}>
            Theme
          </Text>
          <ThemeSwitcher />
        </Flex>

        <SocialMedia />
      </Box>
      <Box flexGrow={1}>
        <Flex alignItems={"center"} mb={"2em"}>
          <Box display={{ base: "flex", sm: "none" }}>
            <MobileMenu />
          </Box>

          <Flex
            w={"100%"}
            justifyContent={"flex-end"}
            pr={"1em"}
            alignItems={"center"}
            alignContent={"center"}
          >
            <Flex pr={"1em"} alignItems={"center"} alignContent={"center"}>
              <ChangeNetworks />
            </Flex>
            <Flex pr={"1em"} alignItems={"center"} alignContent={"center"}>
              <WalletBox />
            </Flex>
          </Flex>
        </Flex>

        <Box
          borderRadius={{ base: 0, sm: "1.5em 0em 0em 0em" }}
          bg={useColorModeValue("white", "#1F1F1F")}
          w={"100%"}
          h={"100%"}
          maxW={"100vw"}
          py={"2.5em"}
          px={"3em"}
        >
          <Outlet />
          {children}
        </Box>
      </Box>
    </Flex>
  );
};

export default DefaultLayout;
