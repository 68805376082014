import { Box, Button, useDisclosure } from "@chakra-ui/react";
import { PlusIcon } from "@heroicons/react/24/solid";
import { FunctionComponent } from "react";
import { useAccount } from "wagmi";
import CreateCampaignModal from "../CreateCampaignModal";

interface CreateCampaignButtonProps {
  setValue: any;
  value: any;
}

const CreateCampaignButton: FunctionComponent<CreateCampaignButtonProps> = ({
  setValue,
  value,
}) => {
  const { isConnected } = useAccount();

  const {
    isOpen: isCreateCampaignModalOpen,
    onOpen: onCreateCampaignModalOpen,
    onClose: onCreateCampaignModalClose,
  } = useDisclosure();
  return (
    <Box mt={{base: "1em", sm: "0em"}}>
      <Button
        isDisabled={!isConnected}
        borderRadius={"3.125em"}
        pl={"2.5em"}
        pr={"2.5em"}
        leftIcon={
          <Box pb={"0.1em"}>
            <PlusIcon width={"1.25em"} hanging={"1.25em"} />
          </Box>
        }
        h={"3em"}
        textTransform={"uppercase"}
        onClick={onCreateCampaignModalOpen}
      >
        Create Campaign
      </Button>
      <CreateCampaignModal
        isOpen={isCreateCampaignModalOpen}
        onClose={onCreateCampaignModalClose}
        setValue={setValue}
        value={value}
      />
    </Box>
  );
};

export default CreateCampaignButton;
