import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { apiClient } from "services/api/apiClient";
import { GenericPagination } from "types";
import { SaleHistoryType, setUpReferralsTokensType, TokenType } from "./type";

interface useSetUpReferralsPropsType {
  pageNumber?: number;
  promotionTokenNetwork: string;
  user?: string;
  value: any;
  status?: string | string[];
}
export const useSetUpReferralsTokens = ({
  pageNumber,
  promotionTokenNetwork,
  user,
  value,
  status,
}: useSetUpReferralsPropsType) => {
  return useQuery<setUpReferralsTokensType>({
    queryKey: [
      "promotions",
      status,
      pageNumber,
      user,
      promotionTokenNetwork,
      value,
    ],
    queryFn: () => {
      return apiClient.get(
        `/promotions/?network_id=${promotionTokenNetwork}&page=${pageNumber}&user=${user}`
      );
    },
    initialData: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    retry: false,
    select: (data) => {
      //TODO: Remove this once backend filtering works for multiple status options
      if (Array.isArray(status)) {
        return {
          ...data,
          results: data.results.filter((e) => status.includes(e.status)),
        };
      }

      return data;
    },
  });
};

export const useFetchTokens = (searchInput?: string, network?: string) => {
  return useInfiniteQuery({
    queryKey: ["tokens", searchInput],
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) => {
      return apiClient.get<unknown, GenericPagination<TokenType>>(
        `/tokens/?network=${network}&page=${pageParam}${
          searchInput ? "&search=" + searchInput : ""
        }`
      );
    },

    getNextPageParam: (lastPage, pages, lastPageParam) => {
      if (lastPage?.next) {
        return lastPageParam + 1;
      }
      return undefined;
    },
  });
};

export const useFetchCampaignStats = (campaignId: string, referrer: string) => {
  return useQuery<SaleHistoryType>({
    queryKey: ["history", campaignId, referrer],
    queryFn: () => {
      return apiClient.get(
        `/history?promotion_id=${campaignId}&referrer=${referrer}`
      );
    },
  });
};
type SaleUserHistoryType = {
  data: {
    total_amount: number;
    date: any;
  }[];
  wallets: number;
  total_emoutn: number;
};
export const useFetchUserCampaignStats = (
  campaignId: string,
  referrer: string
) => {
  return useQuery<SaleUserHistoryType>({
    queryKey: ["userHistory", campaignId, referrer],
    queryFn: () => {
      return apiClient.get(
        `/userHistory?promotion_id=${campaignId}&referrer=${referrer}`
      );
    },
    initialData: {
      data: [
        {
          total_amount: 0,
          date: "2024-06-26",
        },
      ],
      wallets: 0,
      total_emoutn: 0,
    },
  });
};

type StatisticsProps = {
  total_amount: number;
  allWallets: number;
};
export const useGetPromotionStatistics = (campaignId: string) => {
  return useQuery<StatisticsProps>({
    queryKey: ["statistics", campaignId],
    queryFn: () => {
      return apiClient.get(`get-statistics?promotionId=${campaignId}`);
    },
    initialData: {
      total_amount: 0,
      allWallets: 0,
    },
  });
};
