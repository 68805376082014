import { apiClient } from "services/api/apiClient";
import { useQuery } from "@tanstack/react-query";

type ReferralData = {
  referral: string;
  referrer: string;
  promotion: string;
};
type CustomResponce = {
  results: ReferralData[];
};

export const useGetCheckReferral = (
  address?: string | undefined,
  walletAddress?: string | null,
  promotion?: string | null,
  showSign?: boolean
) => {
  return useQuery<ReferralData[]>({
    queryKey: ["CheckReferral", address, walletAddress, promotion, showSign],
    queryFn: async () => {
      if (!address || !walletAddress || !promotion) {
        return [
          {
            referral: "",
            referrer: "",
            promotion: "",
          },
        ];
      }

      const response: CustomResponce = await apiClient.get(
        `/referral/?referral=${address}&referrer=${walletAddress}&promotion=${promotion}`
      );

      return response.results;
    },
    initialData: [
      {
        referral: "",
        referrer: "",
        promotion: "",
      },
    ],
  });
};
