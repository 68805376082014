import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const weak = defineStyle((props) => ({
  opacity: 0.5,
}));

export const headingTheme = defineStyleConfig({
  baseStyle: (props) => ({
    color: mode("#0D0D0D", "white")(props),
    fontFamily: "secondary",
    h: "1.4em",
  }),
  variants: { weak },
  sizes: {
    xs: {
      fontSize: "xs",
    },
    sm: {
      fontSize: "md",
    },
    md: {
      fontSize: "lg",
      fontWeight: 600,
    },

    lg: {
      fontSize: "3xl",
    },
  },
  defaultProps: {
    size: "md",
  },
});
