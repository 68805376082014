import { Center, HStack, useColorModeValue, Image } from "@chakra-ui/react";

import Telegrm from "assets/images/telegram";
import Medium from "assets/images/medium";
import Xlogo from "assets/images/xLogo";
import { PiYoutubeLogoLight } from "react-icons/pi";
import { FunctionComponent } from "react";
import React from "react";

const socialMediaArray = [
  {
    icon: <Xlogo h={"1.6em"} w={"1.6em"} />,
    link: "https://twitter.com/peakdefi",
  },
  {
    icon: <Telegrm w={"1.5em"} h={"1.5em"} />,
    link: "https://t.me/peakdefi_official",
  },
  {
    icon: <PiYoutubeLogoLight style={{width:"1.9em", height:"1.9em"}}/>,
    link: "https://www.youtube.com/@PEAKTechDEFI",
  },
];

interface SocialMediaProps {}

const SocialMedia: FunctionComponent<SocialMediaProps> = () => {
  const iconColor = useColorModeValue("#0D0D0D", "#F5F5F5");

  return (
    <HStack gap={"0.75em"}>
      {socialMediaArray.map((sm) => {
        return (
          <Center
            as={"a"}
            href={sm.link}
            opacity={0.5}
            _hover={{
              opacity: 1,
            }}
            borderRadius={"50%"}
            transition={"all 0.3s"}
            target="_blank"
          >
            {React.cloneElement(sm.icon, { color: iconColor })}
          </Center>
        );
      })}
    </HStack>
  );
};

export default SocialMedia;
