import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Flex,
  IconButton,
  useColorModeValue,
  Box,
  Tooltip,
} from "@chakra-ui/react";
import { HiOutlineDocumentDuplicate } from "react-icons/hi2";
import Token from "features/token/Token";
import { FunctionComponent, useEffect, useState } from "react";
import DottedText from "ui/components/DottedText/DottedText";
import { toast } from "react-toastify";
import { useAccount, useSignMessage } from "wagmi";
import copy from "clipboard-copy";
import { PromotionTokenTypes } from "types";
import WalletBox from "ui/layouts/DefaultLayout/components/WalletBox";

interface PromoteModalProps {
  token: PromotionTokenTypes;
  setSelectedToken: Function;
}

const PromoteModal: FunctionComponent<PromoteModalProps> = ({
  token,
  setSelectedToken,
}) => {
  const [referralLink, setReferralLink] = useState("");
  const { address } = useAccount();
  const bg = useColorModeValue("#E0E0E0", "#FFFFFF33");

  const onCopyClick = () => {
    navigator.clipboard.writeText(
      `${window.location.href}buy-to-earn/?promoteId=${token.id}&walletAddress=${address}`
    );
    toast.success("Link copied to clipboard");
  };

  useEffect(() => {
    setReferralLink(
      `${window.location.href}buy-to-earn/?promoteId=${token.id}&walletAddress=${address}`
    );
  }, [address, token]);

  return (
    <>
      <Modal
        isOpen={!!token}
        onClose={() => {
          setSelectedToken(undefined);
        }}
        isCentered
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Refer to Earn</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              fontFamily={"main"}
              fontSize={"0.875rem"}
              fontWeight={"400"}
              opacity={0.5}
              mb={"2em"}
            >
              Share your referral link and earn rewards when others buy tokens
              through your link.
            </Text>
            <Token
              imgStyle={{
                w: "2.5em",
                h: "2.5em",
                borderRadius: "3.125em",
              }}
              nameStyle={{ fontSize: "0.875em", fontWeight: "600" }}
              shortNameStyle={{
                fontSize: "0.75em",
                fontWeight: "400",
                opacity: "0.5",
              }}
              img={token.token_info.img_url}
              name={token.token_info.name}
              shortName={token.token_info.short_name}
            />
            <Flex gap={"2em"} flexDirection={"column"} mt={"2em"} mb={"3em"}>
              <DottedText
                leftText="Total daily rewards"
                rightText={`${
                  token.total_daily_rewards / 10 ** token.token_info.decimals
                } ${token?.token_info?.short_name}`}
              />
              <DottedText
                leftText="Total campaign rewards"
                rightText={`${
                  token.total_campaign_rewards / 10 ** token.token_info.decimals
                } ${token?.token_info?.short_name}`}
              />
            </Flex>
          </ModalBody>
          {!!address && (
            <Flex
              w={"100%"}
              border={"1px solid "}
              borderColor={bg}
              py={"0.87em"}
              px={"0.75em"}
              pl={"1em"}
              borderRadius={"0.25em"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text
                w={"90%"}
                fontFamily={"main"}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
              >
                {referralLink}
              </Text>

              <Tooltip label={"Copy link to clipboard"}>
                <IconButton
                  aria-label={""}
                  onClick={onCopyClick}
                  icon={<HiOutlineDocumentDuplicate />}
                  variant={"transparent"}
                  minW={"0"}
                  px={0}
                  py={0}
                  h={"fit-content"}
                  fontSize={"1.25em"}
                  opacity={"0.5"}
                />
              </Tooltip>
            </Flex>
          )}

          <ModalFooter>
            {!address && (
              <Flex alignItems={"center"} gap={"0.75rem"}>
                <Text>
                  Please connect your wallet to receive a referral link
                </Text>
                <Box>
                  <WalletBox />
                </Box>
              </Flex>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PromoteModal;
