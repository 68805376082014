import { useState } from "react";
import { toast } from "react-toastify";
import {
  TOKEN_CONTRACT_ABI,
  TOKEN_CONTRACT_ADDRESS,
} from "services/contracts/contractsData/tokenContract";
import {
  useAccount,
  useBalance as useWagmiBalance,
  useContractRead,
  useContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { waitForTransaction } from "@wagmi/core";

export const useAllowance = (
  tokenContractAddress?: `0x${string}`,
  contract_address?: string
) => {
  const { address } = useAccount();
  const rawHook = useContractRead<any, any, bigint>({
    address: tokenContractAddress,
    abi: TOKEN_CONTRACT_ABI,
    functionName: "allowance",
    watch: true,
    args: [address, contract_address],
    enabled: !!contract_address && !!tokenContractAddress,
  });

  return rawHook;
};

export const useBalance = (
  tokenContractAddress: `0x${string}`,
  precision = 2
) => {
  const { address } = useAccount();
  return useWagmiBalance({
    address: address,
    token: tokenContractAddress,
    watch: true,
  });
};

export const useApprove = (tokenContractAddress: `0x${string}`) => {
  const [transactionHash, setTransactionHash] = useState<`0x${string}`>();

  const transactionHook = useWaitForTransaction({
    hash: transactionHash,
  });

  const rawHook = useContractWrite({
    address: tokenContractAddress,
    abi: TOKEN_CONTRACT_ABI,
    functionName: "approve",
    onSuccess: (data) => {
      setTransactionHash(data.hash);
      toast.promise(waitForTransaction({ hash: data.hash }), {
        error: "Transaction failed, please try again later",
        pending: "Transaction pending",
        success: "Transaction successful",
      });
    },
  });

  return {
    ...rawHook,
    write: (contract_address?: string) => {
      if (contract_address) {
        //TODO: find some actual value
        rawHook.write({
          args: [contract_address, 9999999999999n * (10n ** 18n)],
        });
      }
    },
    transactionData: transactionHook,
    blockActions: transactionHook.isLoading || rawHook.isLoading,
  };
};
