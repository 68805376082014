import {
  Center,
  Flex,
  useColorModeValue,
  Text,
  useDisclosure,
  Image,
  useColorMode,
} from "@chakra-ui/react";
import { WalletIcon } from "@heroicons/react/24/outline";
import { FunctionComponent, useEffect, useState } from "react";
import account_placeholder from "assets/images/account_placeholder.svg";
import { useDisconnect, useNetwork, useAccount } from "wagmi";

import ConnectModal from "ui/components/ConnectModal/ConnectModal";
import { useGenericModal } from "ui/components/GenericModalContext/hooks";
interface WalletBoxProps {}

const WalletBox: FunctionComponent<WalletBoxProps> = () => {
  const { address, isConnected } = useAccount();

  const { disconnect } = useDisconnect();

  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { confirmationModal } = useGenericModal();

  const onClick = () => {
    if (isConnected) {
      confirmationModal({
        heading: "Disconnect wallet",
        content:
          "Are you sure you want to disconnect your wallet from this website?",
        confirmContent: "Proceed",
        cancelContent: "Cancel",
        confirmCallback: () => {
          disconnect();
        },
      });
    } else {
      onOpen();
    }
  };

  return (
    <Flex
      h={"2.5em"}
      border={"1px solid"}
      borderColor={useColorModeValue("#E0E0E0", "rgba(255, 255, 255, 0.20)")}
      borderRadius={"2.5em"}
      alignItems={"center"}
      pr={"0.5rem"}
      cursor={"pointer"}
      onClick={() => {
        onClick();
      }}
    >
      <ConnectModal isOpen={isOpen} onClose={onClose} />
      <Center
        h={"2.4em"}
        w={"2.4em"}
        bg={useColorModeValue("#E0E0E0", "rgba(255, 255, 255, 0.20)")}
        borderRadius={"50%"}
      >
        {address && <Image src={account_placeholder} />}
        {!address && (
          <WalletIcon
            color={colorMode === "light" ? "#0D0D0D" : "#FFFFFF"}
            height={"1.1em"}
          />
        )}
      </Center>
      <Center px={"0.75em"}>
        {!isConnected && (
          <Text textTransform={"uppercase"} size={"md"} fontWeight={"500"}>
            Connect
          </Text>
        )}

        {isConnected && address && (
          <Text
            textTransform={"uppercase"}
            size={"sm"}
            fontWeight={"500"}
            fontFamily={"main"}
          >
            {address.slice(0, 4) + "..." + address.slice(-4)}
          </Text>
        )}
      </Center>
    </Flex>
  );
};

export default WalletBox;
