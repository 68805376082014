import { defineStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const Button = defineStyleConfig({
  baseStyle: {
    fontSize: "sm",
    fontFamily: "secondary",
    textTransform: "uppercase",
    transition: "all 0.3s ease-out",
  },

  variants: {
    filled: {
      background: "linear-gradient(270deg, #0AA7F5 0%, #3CE7FF 100%)",
      height: "auto",
      color: "#0D0D0D",

      _disabled: {
        opacity: 0.5,
        background:
          "linear-gradient(270deg, #0AA7F5 0%, #3CE7FF 100%) !important",
        _hover: {},
      },

      _hover: {
        boxShadow: "0.25em 0.25em black",
        transform: "translate(-0.25em, -0.25em)",
      },
    },

    outline: (props) => ({
      border: mode("1px solid black", "1px solid white")(props),
      bg: "transparent",
      height: "auto",
      fontSize: "sm",
      color: mode("dark.500", "light.500")(props),
      py: "0.5em",
      px: "1em",
      minWidth: "10em",
      _disabled: {
        opacity: 0.5,
      },

      _hover: {
        boxShadow: "0.25em 0.25em black",
        transform: "translate(-0.25em, -0.25em)",
        background: "linear-gradient(270deg, #0AA7F5 0%, #3CE7FF 100%)",
      },
    }),

    dark: {
      border: "1px solid black",

      height: "auto",
      color: "#3CE7FF",
      py: "0.5em",
      px: "1em",
      fontSize: "sm",
      background: "dark.400",
      minWidth: "10em",
      _disabled: {
        opacity: 0.5,
      },

      zIndex: 1,

      _hover: {
        boxShadow: "inset 0.25em 0.25em #0AA7F5 inset #3CE7FF 100%",
        transform: "translate(-0.25em, -0.25em)",
      },
    },

    square: {
      background: "linear-gradient(270deg, #0AA7F5 0%, #3CE7FF 100%)",
      aspectRatio: "1/1",
      minWidth: "none",

      color: "#0D0D0D",
      fontWeight: 500,
      fontSize: "md",
      px: "0.5rem !important",
      borderRadius: "0.25em",
    },
  },

  sizes: {
    md: {
      fontSize: "sm",
      py: "0.5em",
      px: "1em",
      minWidth: "10em",
      borderRadius: "1em",
    },
    lg: {
      py: "1em",
      fontSize: "sm",
      minW: "12.5em",
      borderRadius: "1.5em",
    },
  },

  defaultProps: {
    variant: "filled",
    size: "md",
  },
});
