import { sliderAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);
const baseStyle = definePartsStyle({
  thumb: {
    bg: "linear-gradient(270deg, #0AA7F5 0%, #3CE7FF 100%)",
    h: "1.25em",
    w: "1.25em",

    borderRadius: "50%",
    border: "4px solid #fff",
    borderColor: "white !important",

    _focus: {
      boxShadow: "none !important",
    },
  },
  track: {
    h: "0.75em",
    borderRadius: "0.75em",

    bg: "#E0E0E0",

    _dark: {
      bg: "#252525",
    },
  },

  filledTrack: {
    bg: "linear-gradient(270deg, #0AA7F5 0%, #3CE7FF 100%)",
  },
});
export const sliderTheme = defineMultiStyleConfig({ baseStyle });
