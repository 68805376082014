import {
  Button,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { disconnect } from "process";
import { FunctionComponent, ReactNode, createContext, useState } from "react";
import { switchNetwork } from "services/Web3/consts/connections";
import { ConfirmationModalType, InfoModalParamsType } from "./types";

interface GenericModalContextProviderProps {
  children: ReactNode | ReactNode[];
}

export const GenericModalContext = createContext({
  confirmationModal: (confirmParams: ConfirmationModalType) => {},
  infoModal: (infoParams: InfoModalParamsType) => {},
});

const GenericModalContextProvider: FunctionComponent<
  GenericModalContextProviderProps
> = ({ children }) => {
  //const UserContext = createContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [cta, setCta] = useState<ReactNode | ReactNode[]>(
    <>
      <Button
        fontSize={"0.85em"}
        w={"13em"}
        onClick={() => {
          onClose();
        }}
      >
        Okay
      </Button>
    </>
  );

  const [header, setHeader] = useState<string | ReactNode | ReactNode[]>(
    "Warning!"
  );

  const [content, setContent] = useState<ReactNode | ReactNode[]>(
    <Text
      fontFamily={"main"}
      fontSize={"0.875rem"}
      fontWeight={"400"}
      opacity={0.5}
      mt={"1em"}
      mb={"1.25em"}
    >
      PLACEHOLDER TEXT
    </Text>
  );

  const resetStates = () => {
    setContent(
      <Text
        fontFamily={"main"}
        fontSize={"0.875rem"}
        fontWeight={"400"}
        opacity={0.5}
        mt={"1em"}
        mb={"1.25em"}
      >
        PLACEHOLDER TEXT
      </Text>
    );

    setHeader("PLACEHOLDER");

    setCta(
      <>
        <Button
          fontSize={"0.85em"}
          w={"13em"}
          onClick={() => {
            onClose();
          }}
        >
          Okay
        </Button>
      </>
    );

    onClose();
  };

  const invokeConfirmationModal = ({
    heading,
    content,
    cancelContent = "Cancel",
    cancelCallback = onClose,
    confirmCallback,
    confirmContent = "Okay",
  }: ConfirmationModalType) => {
    setHeader(heading);
    setContent(
      <Text
        fontFamily={"main"}
        fontSize={"0.875rem"}
        fontWeight={"400"}
        opacity={0.5}
        mt={"1em"}
        mb={"1.25em"}
      >
        {content}
      </Text>
    );
    setCta(
      <>
        <Button
          fontSize={"0.85em"}
          w={"13em"}
          variant={'outline'}
          onClick={() => {
            cancelCallback?.();
            onClose();
          }}
        >
          {cancelContent}
        </Button>
        <Button
          fontSize={"0.85em"}
          w={"13em"}
          onClick={() => {
            confirmCallback?.();
            onClose();
          }}
        >
          {confirmContent}
        </Button>
      </>
    );
    onOpen();
  };

  const invokeInfoModal = ({
    content,
    cta,
    heading,
    onAccept,
  }: InfoModalParamsType) => {
    resetStates();

    setContent(content);
    setCta(
      <>
        <Button
          fontSize={"0.85em"}
          w={"13em"}
          onClick={() => {
            onClose();
            onAccept?.();
          }}
        >
          {cta}
        </Button>
      </>
    );
    setHeader(heading);
  };

  return (
    <GenericModalContext.Provider
      value={{
        confirmationModal: invokeConfirmationModal,
        infoModal: invokeInfoModal,
      }}
    >
      {children}
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"2xl"}>
        <ModalOverlay />
        <ModalContent
          overflow={"hidden"}
          bg={useColorModeValue("#FFFFFF", "#1A1A1A")}
        >
          <ModalHeader textAlign={"center"}>{header}</ModalHeader>

          <ModalBody>
            <Center flexDirection={"column"} alignContent={"center"}>
              {content}
              <Flex justifyContent={"space-around"} w={"100%"} mt={'1rem'}>
                {cta}
              </Flex>
            </Center>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </GenericModalContext.Provider>
  );
};

export default GenericModalContextProvider;
