import { Button } from "@chakra-ui/react";
import { FunctionComponent, useEffect } from "react";
import { RewardsTokenInterface } from "types";
import { useAccount } from "wagmi";
import {
  useHarvestToken,
  useToFarmHash,
  useToAmount,
} from "services/contracts/contractHooks/referralCampaignContract/managedReferralCampaignHooks";

interface RewardsButtonProps {
  promotion: RewardsTokenInterface;
  refetch: Function;
}

const RewardsButton: FunctionComponent<RewardsButtonProps> = ({
  promotion,
  refetch,
}) => {
  const { address } = useAccount();
  const { data: farmHash } = useToFarmHash(
    promotion.promotion.user,
    promotion.promotion.token_info.token_contract,
    promotion.promotion.farm_id
  );
  const { write: harvestToken, blockActions } = useHarvestToken(
    farmHash as `0x${string}`
  );
  const { data } = useToAmount(farmHash, address?.toLowerCase());

  let amount = data || 0;
  amount = parseFloat(amount.toString()) || 0;

  useEffect(() => {
    refetch();
  }, [blockActions]);

  return (
    <Button
      isDisabled={amount === 0}
      onClick={() => {
        harvestToken();
      }}
      isLoading={blockActions}
    >
      Claim
    </Button>
  );
};

export default RewardsButton;
