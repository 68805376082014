import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import InfoItem from "./InfoItem";

interface HowItWorksProps {}

const HowItWorks: FunctionComponent<HowItWorksProps> = () => {
  return (
    <Flex
      bg={useColorModeValue("#F5F5F5", "#1A1A1A")}
      borderRadius={"0.25em"}
      padding={"1em"}
      pl={"2em"}
      pr={"2em"}
      justifyContent={"space-between"}
      mt={"1.25em"}
      flexDirection={"column"}
    >
      <Box
        pb={"0.5em"}
        borderBottom={"1px"}
        w={"100%"}
        borderColor={useColorModeValue("#E0E0E0", "rgba(255, 255, 255, 0.20)")}
      >
        <Text
          fontSize={"1em"}
          fontWeight={600}
          textTransform={"uppercase"}
          fontFamily={"secondary"}
        >
          How it works
        </Text>
      </Box>
      <Flex justifyContent={"space-between"}>
        <InfoItem
          number={"1"}
          firstTitle="Connect your wallet."
          secondTitle=""
        />
        <InfoItem
          number={"2"}
          firstTitle="Share the referral link."
          secondTitle=""
        />
        <InfoItem
          number={"3"}
          firstTitle="Wait for your rewards."
          secondTitle=""
        />
      </Flex>
    </Flex>
  );
};

export default HowItWorks;
