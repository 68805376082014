import { FunctionComponent } from "react";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalBody,
  Image,
  ModalOverlay,
  Text,
  Center,
  useColorModeValue,
} from "@chakra-ui/react";
import metamask from "assets/images/metamask.svg";
import walletConnectImage from "assets/images/walletConnect.svg";
import { useConnect } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import * as wagmiChains from "wagmi/chains";

interface ConnectModallProps {
  isOpen: boolean;
  onClose: Function;
}

const ConnectModal: FunctionComponent<ConnectModallProps> = ({
  isOpen,
  onClose,
}) => {
  const metaMaskConnect = useConnect({
    connector: new MetaMaskConnector(),
    chainId: parseFloat(
      (window.ethereum?.networkVersion ?? "56").split(" ")[0]
    ),
  });

  const walletConnect = useConnect({
    connector: new WalletConnectConnector({
      chains: (process.env.REACT_APP_SUPPORTED_CHAIN_IDS ?? "56")
        .split(" ")
        .map((item) => {
          return Object.entries(wagmiChains).find(([id, chain]) => {
            return chain.id === parseInt(item);
          })!![1];
        }),
      options: {
        projectId: "2e23d1f33aa1cdaabcfbff7168090b36",
        showQrModal: true,
      },
    }),
  });

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        isCentered
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent
          w={{ base: "90%", sm: "40%" }}
          padding={"0px"}
          overflow={"hidden"}
          bg={useColorModeValue("#FFFFFF", "#1A1A1A")}
        >
          <ModalBody>
            <Center
              justifyContent={"space-around"}
              flexDirection={"column"}
              padding={"2px"}
            >
              <Center
                display={{ base: "none", sm: "flex" }}
                padding={"1.25em"}
                pt={"2em"}
                borderTopRadius={"0.325em"}
                cursor={"pointer"}
                pb={"2em"}
                bg={useColorModeValue("#FAFAFA", "#252525")}
                w={"100%"}
                h={"100%"}
                mb={"2px"}
                _hover={{
                  backgroundColor: useColorModeValue("#FFFFFF", "#1A1A1A"),
                }}
                flexDirection={"column"}
                onClick={() => {
                  if (
                    window.ethereum?.networkVersion !== "8453" &&
                    window.ethereum?.networkVersion !== "56"
                  ) {
                    window.ethereum
                      .request({
                        method: "wallet_addEthereumChain",
                        params: [
                          {
                            chainName: "Base Mainnet",
                            chainId: "0x2105",
                            nativeCurrency: {
                              name: "ETH",
                              decimals: 18,
                              symbol: "ETH",
                            },
                            rpcUrls: ["https://mainnet.base.org"],
                          },
                        ],
                      })
                      .then(() => {
                        onClose(false);
                        metaMaskConnect.connect({
                          chainId: 8453,
                        });
                      });
                  } else {
                    onClose(false);
                    metaMaskConnect.connect();
                  }
                }}
              >
                <Image src={metamask} w={"2.5em"} />
                <Text
                  fontFamily={"main"}
                  fontSize={"1.5em"}
                  fontWeight={600}
                  mt={"0.5em"}
                >
                  MetaMask
                </Text>
                <Text fontFamily={"secondary"} fontSize={"1em"} mt={"0.5em"}>
                  Connect to your MetaMask Wallet
                </Text>
              </Center>
              <Center
                borderBottomRadius={"0.325em"}
                padding={"1.25em"}
                cursor={"pointer"}
                pb={"2em"}
                bg={useColorModeValue("#FAFAFA", "#252525")}
                w={"100%"}
                h={"100%"}
                _hover={{
                  backgroundColor: useColorModeValue("#FFFFFF", "#1A1A1A"),
                }}
                flexDirection={"column"}
                onClick={() => {
                  onClose(false);
                  walletConnect.connect();
                }}
              >
                <Image src={walletConnectImage} w={"4em"} />
                <Text
                  fontFamily={"main"}
                  fontSize={"1.5em"}
                  fontWeight={600}
                  mt={"0.5em"}
                >
                  WalletConnect
                </Text>
                <Text fontFamily={"secondary"} fontSize={"1em"} mt={"0.5em"}>
                  Scan with WalletConnect to connect
                </Text>
              </Center>
            </Center>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConnectModal;
