import { Text, Flex, Box } from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import { useAccount } from "wagmi";
import { ClockIcon } from "@heroicons/react/24/outline";
import CountdownTimer from "ui/components/Timer";
import Table from "features/rewardsPage/components/RewardsTable";

interface RewardsaPageProps {}

const RewardsaPage: FunctionComponent<RewardsaPageProps> = () => {
  const timeLeft = () => {
    const now = new Date();
    const nowInMsec = now.getTime();
    const dayEndInMsec = new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate() + 1,
        0,
        0,
        0
      )
    ).getTime();
    const timeLeft = dayEndInMsec - nowInMsec;
    const timeLeftDate = new Date(now.getTime() + timeLeft);
    return timeLeftDate;
  };

  return (
    <>
      <Flex
        justifyContent={"space-between"}
        flexDirection={{ base: "column", sm: "row" }}
      >
        <Box>
          <Text
            fontFamily="secondary"
            fontSize={{ base: "2em", sm: "2.5em" }}
            fontWeight={"600"}
          >
            Rewards
          </Text>
          <Text
            mt={"0.5em"}
            fontSize={"0.875em"}
            fontWeight={"400"}
            opacity={0.5}
            fontFamily={"main"}
          >
            Claim your campaign rewards for every successful referral.
          </Text>
        </Box>
        <Flex alignItems={"end"} mt={{ base: "1em", sm: "0em" }}>
          <ClockIcon width={"1.25em"} height={"1.25em"} opacity={0.5} />
          <Flex mb={"0.1em"} alignItems={"end"}>
            <Text
              ml={"0.375em"}
              opacity={0.5}
              fontSize={"0.875em"}
              fontFamily={"secondary"}
              fontWeight={"500"}
              mr={"0.5em"}
              textTransform={"uppercase"}
            >
              calculation of daily rewards in
            </Text>
            <CountdownTimer endDate={timeLeft()} />
          </Flex>
        </Flex>
      </Flex>
      <Table />
    </>
  );
};

export default RewardsaPage;
