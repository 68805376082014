import { FunctionComponent } from "react";
import {
  LinkProps as ChakraLinkProps,
  Link as ChakraLink,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";

interface LinkProps extends ChakraLinkProps {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  to?: string;
}

const Link: FunctionComponent<LinkProps> = ({
  leftIcon,
  rightIcon,
  ...rest
}) => {
  const defaultColor = useColorModeValue("#0D0D0D", "#FFFFFF");
  const activeColor = useColorModeValue("#0D0D0D", "#3CE7FF");
  return (
    <ChakraLink {...rest}>
      {leftIcon && (
        <Box
          h={"1.1em"}
          w={`1.1em`}
          mr={"0.5em"}
          color={rest.variant === "active" ? activeColor : defaultColor}
        >
          {leftIcon}
        </Box>
      )}
      {rest.children}
      {rightIcon && (
        <Box
          h={"1.1em"}
          w={`1.1em`}
          ml={"0.5em"}
          color={rest.variant === "active" ? activeColor : defaultColor}
        >
          {rightIcon}
        </Box>
      )}
    </ChakraLink>
  );
};

export default Link;
