import { Box, Heading, useColorModeValue, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ScriptableContext,
} from "chart.js";
import { PromotionTokenTypes } from "types";
import {
  useFetchUserCampaignStats,
  useGetPromotionStatistics,
} from "features/setUpReferrals/api/client";
import { useAccount } from "wagmi";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface CampaignStatsProps {
  campaignInfo: any;
}

const CampaignStats: FunctionComponent<CampaignStatsProps> = ({
  campaignInfo,
}) => {
  const { address } = useAccount();
  
  const { data: historyData } = useFetchUserCampaignStats(
    campaignInfo.id,
    address?.toLowerCase() ?? ""
  );
  const transformedData = {
    timestamps: historyData?.data.map((e) => e.date) ?? [],
    values:
      historyData?.data.map(
        (e) => e.total_amount / Math.pow(10, campaignInfo.token_info.decimals)
      ) ?? [],
  };
  const lineColor = useColorModeValue("#0AA7F5", "#3CE7FF");

  const data = () => {
    return {
      labels: transformedData.timestamps.map((e) =>
        new Date(e).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
      ),

      datasets: [
        {
          label: "Tokens sold",
          data: transformedData.values,
          borderColor: lineColor,
          backgroundColor: (context: ScriptableContext<"line">) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 150);
            gradient.addColorStop(0, lineColor + "ff");
            gradient.addColorStop(1, lineColor + "00");
            return gradient;
          },
          tension: 0.4,
          fill: "start",
        },
      ],
    };
  };

  return (
    <Box w={"100%"} p={"2rem"} pt={"1rem"}>
      <Heading>Statistics for {campaignInfo.token_info.name} Campaign</Heading>
      <Text fontSize={"1rem"} opacity={"0.8"} mb={"1rem"} mt={"0.8rem"}>
        Wallets participated: {historyData?.wallets}
      </Text>
      <Text fontSize={"1rem"} opacity={"0.8"} mb={"1rem"}>
        Total tokens purchased:{" "}
        {(
          historyData.total_emoutn /
          Math.pow(10, campaignInfo.token_info.decimals)
        ).toFixed(4)}
      </Text>
      <Box mb={"1em"} h={"16em"}>
        <Line
          options={{
            responsive: true,
            maintainAspectRatio: false,

            plugins: {
              filler: {
                propagate: false,
              },
              legend: {
                position: "top" as const,
                display: false,
              },
              title: {
                display: false,
                text: "Sale progress",
              },
            },
            scales: {
              x: {
                border: {
                  width: 2,
                  color: useColorModeValue("#0D0D0D50", "#FFFFFF50"),
                },

                grid: {
                  color: useColorModeValue("#0D0D0D30", "#FFFFFF30"),
                },
                ticks: {
                  color: useColorModeValue("black", "#FFFFFF90"),

                  font: {
                    family: "Manrope, sans-serif",
                    weight: 600,
                  },
                },
              },

              y: {
                suggestedMin: 0,
                suggestedMax: Math.max(...transformedData.values) * 1.2,
                border: {
                  width: 2,
                  color: useColorModeValue("#0D0D0D50", "#FFFFFF50"),
                },

                ticks: {
                  color: useColorModeValue("black", "#FFFFFF90"),

                  font: {
                    family: "Manrope, sans-serif",
                    weight: 600,
                  },
                },

                grid: {
                  color: useColorModeValue("#0D0D0D30", "#FFFFFF30"),
                },
              },
            },
          }}
          data={data()}
        />
      </Box>
    </Box>
  );
};

export default CampaignStats;
