import {
  Flex,
  Heading,
  IconButton,
  VStack,
  Text,
  SimpleGrid,
  Box,
  Input,
  Stack,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { CreateCampaignFormType } from "features/setUpReferrals/api/type";
import Token from "features/token/Token";
import { FormikContextType } from "formik";
import { FunctionComponent } from "react";
import { FaCalendar } from "react-icons/fa";
import { HiArrowLeft } from "react-icons/hi2";
import TokenSelect from "ui/components/TokenSelect";

interface CreateFormDepositRewardsProps {
  formik: FormikContextType<CreateCampaignFormType>;
  onPropagate(): void;
}

const CreateFormDepositRewards: FunctionComponent<
  CreateFormDepositRewardsProps
> = ({ formik, onPropagate }) => {
  const propagationCondition =
    !!formik.values.token &&
    formik.values.tokensDistributedCampaign > 0 &&
    !!formik.values.startDate &&
    !!formik.values.endDate;

  return (
    <VStack alignItems="stretch" spacing={0}>
      <Flex alignItems={"center"} gap={"1.25em"} mb={"1.25em"}>
        <IconButton
          opacity={"0.5"}
          aria-label={""}
          icon={<HiArrowLeft fontSize={"1.5em"} />}
          variant={"transparent"}
          minW={0}
          onClick={() => {
            formik.setFieldValue("token", undefined);
          }}
        />
        <Token
          img={formik.values.token?.img_url!!}
          name={formik.values.token?.name!!}
          shortName={formik.values.token?.short_name!!}
        />
      </Flex>
      <Heading fontSize={{ base: "1.5em", sm: "xxl" }}>
        {formik.values.token?.short_name} Referral Campaign
      </Heading>
      <Text fontFamily={"main"} opacity={"0.5"} mb={"2em"}>
        Provide the specifics for the referral campaign you're creating.
      </Text>

      <SimpleGrid
        columns={2}
        columnGap={"2em"}
        rowGap={"2em"}
        w={"100%"}
        mb={"3em"}
      >
        <Box>
          <Text
            size={"sm"}
            opacity={"0.5"}
            display={{base:"flex", sm: "none"}}
            color={"transparent"}
            textTransform={"uppercase"}
          >
            Token
          </Text>
          <Text
            size={"sm"}
            opacity={"0.5"}
            mb={"0.5em"}
            textTransform={"uppercase"}
          >
            Token
          </Text>
          <TokenSelect
            value={formik.values.token}
            onChange={(newValue) => {
              formik.setFieldValue("token", newValue);
            }}
          />
        </Box>
        <Stack spacing={0}>
          <Text
            size={"sm"}
            opacity={"0.5"}
            mb={"0.5em"}
            textTransform={"uppercase"}
          >
            Tokens Distributed During the Campaign
          </Text>
          <InputGroup>
            <Input
              type="number"
              min={0}
              minH={"3em"}
              value={formik.values.tokensDistributedCampaign}
              borderColor={useColorModeValue(
                "#E0E0E0",
                "rgba(255, 255, 255, 0.20)"
              )}
              borderRadius={"0.25em"}
              onChange={(e) => {
                formik.setFieldValue(
                  "tokensDistributedCampaign",
                  e.target.valueAsNumber
                );
              }}
            />
            <InputRightElement pr={"0.75em"}>
              <Text fontWeight={600} fontFamily={"main"}>
                {formik.values.token?.short_name}
              </Text>
            </InputRightElement>
          </InputGroup>
        </Stack>

        <Stack spacing={0}>
          <Text
            size={"sm"}
            opacity={"0.5"}
            mb={"0.5em"}
            textTransform={"uppercase"}
          >
            Campaign Start Date
          </Text>
          <InputGroup flexGrow={"1"}>
            <Input
              type="date"
              value={formik.values.startDate}
              placeholder={"Set a Date"}
              h={"100%"}
              borderColor={useColorModeValue(
                "#E0E0E0",
                "rgba(255, 255, 255, 0.20)"
              )}
              borderRadius={"0.25em"}
              onChange={(e) => {
                formik.setFieldValue(
                  "startDate",
                  e.target.valueAsDate?.toISOString().slice(0, 10)
                );
              }}
            />
          </InputGroup>
        </Stack>
        <Stack spacing={0}>
          <Text
            size={"sm"}
            opacity={"0.5"}
            mb={"0.5em"}
            textTransform={"uppercase"}
          >
            Campaign End Date
          </Text>
          <InputGroup flexGrow={"1"}>
            <Input
              type="date"
              value={formik.values.endDate}
              placeholder={"Set a Date"}
              min={
                formik.values.startDate !== ""
                  ? new Date(formik.values.startDate)
                      .toISOString()
                      .split("T")[0]
                  : undefined
              }
              h={"100%"}
              borderColor={useColorModeValue(
                "#E0E0E0",
                "rgba(255, 255, 255, 0.20)"
              )}
              borderRadius={"0.25em"}
              onChange={(e) => {
                formik.setFieldValue(
                  "endDate",
                  e.target.valueAsDate?.toISOString().slice(0, 10)
                );
              }}
            />
          </InputGroup>
        </Stack>
      </SimpleGrid>

      <Button
        alignSelf={"flex-end"}
        size={"lg"}
        px={"3em"}
        fontFamily="main"
        isDisabled={!propagationCondition}
        onClick={() => {
          onPropagate();
        }}
      >
        Deposit rewards
      </Button>
    </VStack>
  );
};

export default CreateFormDepositRewards;
