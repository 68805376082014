import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { toast } from "react-toastify";

interface InfoTableProps {
  dailyRewards: number;
  campaignRewards: number;
  token: string;
  shortName: string;
}

const InfoTable: FunctionComponent<InfoTableProps> = ({
  dailyRewards,
  campaignRewards,
  token,
  shortName,
}) => {
  const onCopyClick = () => {
    navigator.clipboard.writeText(`${token}`);

    toast.success("Address copied to clipboard");
  };

  return (
    <Flex
      bg={useColorModeValue("#FFFFFF", "#252525")}
      borderRadius={"0.25em"}
      padding={"2em"}
      justifyContent={"space-between"}
      mt={"1.25em"}
      flexDirection={{ base: "column", sm: "row" }}
      gap={{ base: "1em", sm: "0em" }}
    >
      <Box>
        <Text
          textTransform={"uppercase"}
          fontWeight={"500"}
          fontSize={"0.75em"}
          fontFamily={"secondary"}
          opacity={"0.5"}
        >
          Total daily rewards
        </Text>
        <Text
          fontWeight={"600"}
          fontSize={"1.25em"}
          fontFamily={"main"}
          mt={"2px"}
        >{`${dailyRewards} ${shortName}`}</Text>
      </Box>
      <Box>
        <Text
          textTransform={"uppercase"}
          fontWeight={"500"}
          fontSize={"0.75em"}
          opacity={"0.5"}
          fontFamily={"secondary"}
        >
          Total campaign rewards
        </Text>
        <Text
          fontWeight={"600"}
          fontSize={"1.25em"}
          fontFamily={"main"}
          mt={"2px"}
        >{`${campaignRewards} ${shortName}`}</Text>
      </Box>
      <Box>
        <Text
          textTransform={"uppercase"}
          fontWeight={"500"}
          opacity={"0.5"}
          fontSize={"0.75em"}
          fontFamily={"secondary"}
        >
          token address
        </Text>
        <Text
          onClick={() => {
            onCopyClick();
          }}
          cursor={"pointer"}
          fontWeight={"600"}
          fontSize={"1.25em"}
          fontFamily={"main"}
          mt={"2px"}
        >
          {token.slice(0, 15) + "..." + token.slice(-4)}
        </Text>
      </Box>
    </Flex>
  );
};

export default InfoTable;
