import {
  Avatar,
  Box,
  Center,
  Flex,
  Spinner,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useFetchTokens } from "features/setUpReferrals/api/client";
import { TokenType } from "features/setUpReferrals/api/type";
import Token from "features/token/Token";
import { FunctionComponent } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";
import InfiniteScroll from "react-infinite-scroll-component";

interface TokenSelectProps {
  value?: TokenType;

  onChange(newValue: TokenType): void;
}

const TokenSelect: FunctionComponent<TokenSelectProps> = ({
  value,
  onChange,
}) => {
  const {
    isOpen: isDropdownOpen,
    onOpen: onDropdownOpen,
    onClose: onDropdownClose,
    onToggle: onDropdownToggle,
  } = useDisclosure();

  const { colorMode } = useColorMode();

  const {
    data: tokensData,
    isLoading: isTokensDataLoading,
    ...infiniteScrollProps
  } = useFetchTokens();

  return (
    <Box position={"relative"}>
      <Flex
        w={"100%"}
        px={"0.75em"}
        h={'3em'}
        borderColor={useColorModeValue(
          "#E0E0E0 !important",
          "rgba(255, 255, 255, 0.2) !important"
        )}
        border={"1px solid"}
        borderRadius={"0.25em"}
        justifyContent={"space-between"}
        alignItems={"center"}
        cursor={"pointer"}
        onClick={onDropdownToggle}
      >
        <Flex gap={"0.5em"} alignItems={"center"}>
          <Avatar src={value?.img_url} h={"1.5em"} w={"1.5em"} />
          <Text fontWeight={"600"} fontFamily="main" fontSize={"sm"}>
            {value?.short_name}
          </Text>
        </Flex>

        <Box
          transform={isDropdownOpen ? "rotate(180deg)" : ""}
          transition={"0.3s ease-out"}
        >
          <HiChevronDown />
        </Box>
      </Flex>

      <Box
        h={"12em"}
        bg={useColorModeValue("#FAFAFA", "#252525")}
        borderRadius={"0.25em"}
        borderWidth={isDropdownOpen ? "1px" : "0px"}
        borderColor={useColorModeValue("#E0E0E0", "rgba(255, 255, 255, 0.20)")}
        overflow={"hidden"}
        w={"100%"}
        position={"absolute"}
        mt={"0.125em"}
        transition={"0.3s ease-out"}
        boxSizing={"content-box"}
        maxH={isDropdownOpen ? "12em" : "0"}
        id={"scrollableDiv"}
        overflowY={isDropdownOpen ? "scroll" : "hidden"}
        zIndex={'1000000'}
      >
        {isTokensDataLoading && (
          <Center h={"100%"} w={"100%"}>
            <Spinner size={"xl"} />
          </Center>
        )}
        <InfiniteScroll
          next={() => {
            infiniteScrollProps.fetchNextPage();
          }}
          hasMore={infiniteScrollProps.hasNextPage}
          loader={
            <Center w={"100%"}>
              <Spinner size="sm" />
            </Center>
          }
          dataLength={tokensData?.pages.length ?? 0}
          scrollableTarget="scrollableDiv"
          style={{ overflow: "hidden" }}
        >
          {tokensData?.pages
            .flatMap((page) => page.results)
            .map((token) => {
              return (
                <Flex
                  gap={"0.5em"}
                  alignItems={"center"}
                  py={"0.5em"}
                  cursor={"pointer"}
                  onClick={() => {
                    onChange(token);
                  }}
                  px={"0.75em"}
                  _hover={{
                    bg:
                      colorMode === "light"
                        ? "rgba(0, 0, 0, 0.1)"
                        : "rgba(255, 255,255, 0.1)",
                  }}
                >
                  <Avatar
                    src={token.img_url}
                    h={"1.5em"}
                    w={"1.5em"}
                    name={token.name}
                  />
                  <Text fontWeight={"600"} fontFamily="main" fontSize={"sm"}>
                    {token.short_name}
                  </Text>
                </Flex>
              );
            })}
        </InfiniteScroll>
      </Box>
    </Box>
  );
};

export default TokenSelect;
