import { Box, Text, Image, StyleProps, Flex } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { FiHelpCircle } from "react-icons/fi";
interface TokenProps {
  img: string;
  name: string;
  shortName: string;
  imgStyle?: StyleProps;
  nameStyle?: StyleProps;
  shortNameStyle?: StyleProps;
  containerStyle?: StyleProps;
}

const Token: FunctionComponent<TokenProps> = (props) => {
  return (
    <Flex>
      {props.img !== "null" && (
        <Image
          h={"2.5em"}
          {...props.imgStyle}
          src={props.img}
          alt={"token icon"}
        />
      )}
      {props.img === "null" && <FiHelpCircle fontSize={"2.5em"} />}
      <Flex
        flexDirection={"column"}
        ml={"0.5em"}
        mb={"2px"}
        textAlign={"start"}
        justifyContent={"space-around"}
      >
        <Text fontSize="0.875em" fontWeight="600" {...props.nameStyle}>
          {props.name}
        </Text>
        <Text
          fontSize="0.75em"
          fontWeight="400"
          opacity="0.5"
          {...props.shortNameStyle}
        >
          {props.shortName}
        </Text>
      </Flex>
    </Flex>
  );
};

export default Token;
