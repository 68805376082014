import { Flex } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import FAQItem from "ui/components/FAQItem/FAQItem";
interface FAQProps {}

const FAQ: FunctionComponent<FAQProps> = () => {
  return (
    <Flex w={"100%"} h={"100%"} flexDirection={"column"} mt={"-1.125em"}>
      <FAQItem
        title="1. How does the PEAKDEFI promotion protocol work?"
        text="The PEAKDEFI promotion protocol connects affiliates with projects, rewarding affiliates for promoting tokens while providing projects with increased outreach. All transactions occur on-chain. When users buy tokens on Uniswap, affiliates receive rewards."
      />
      <FAQItem
        title="2. How can I participate in the PEAKDEFI promotion protocol as a user/affiliate?"
        text='To participate, go to the "Promote to Earn" section and select the token you want to promote by clicking the "Refer to Earn" button. Your individual referral link will be generated, allowing you to start sharing and earning rewards'
      />
      <FAQItem
        title="3. How can I set up an affiliate campaign for a token?"
        text='To set up an affiliate campaign, go to the "Setup a Campaign" section and create a campaign. Ensure your wallet is connected to the Base Blockchain. You can define the campaign duration and the daily rewards for affiliates. The higher the daily rewards, the greater the outreach you can achieve.'
      />
      <FAQItem
        title="4. On which Blockchain Network the promotion protocol works?"
        text="Currently, the promotion protocol operates exclusively on the Base Blockchain."
      />
      <FAQItem
        title="5. How does the affiliate plan work? How can I earn?"
        text="The affiliate rewards will be distributed on a daily basis between the affiliates. The amount of the rewards depends on the one hand on the fixed daily rewards of the token campaign and on the other hand on the purchasing power of the individual users that the affiliates have brought."
      />
      <FAQItem
        title="6. When I get my rewards and how can I claim them?"
        text="The daily rewards are distributed every day at 0.00 GMT time between all affiliates who have successfully recommended tokens. You can claim them in the “Rewards” section by clicking on the “Claim” button. The amount of commission depends on the purchase volumes of the users."
      />
    </Flex>
  );
};

export default FAQ;
