import { Center, Heading } from "@chakra-ui/react";
import { FunctionComponent } from "react";

interface CreateFormSuccessProps {}

const CreateFormSuccess: FunctionComponent<CreateFormSuccessProps> = () => {
  return (
    <Center h={"100%"} minH={"20em"}>
      <Heading opacity={"0.5"} textAlign={"center"}>
        Your campaign will become visible once it has been confirmed on the
        blockchain
      </Heading>
    </Center>
  );
};

export default CreateFormSuccess;
