import { apiClient } from "services/api/apiClient";
import { useQuery } from "@tanstack/react-query";


interface LeaderboardItemType {
  wallet: string;
  referrals: string;
  amount: string;
  promotion_amount: string;
  participated_promotions: string;
}

interface LeaderboardType {
  count: number | null;
  next: string | null;
  previous: string | null;
  results: LeaderboardItemType[];
}

export const useLeaderboard = () => {
  return useQuery<LeaderboardType>({
    queryKey: ["leaderboard"],
    queryFn: () => {
      return apiClient.get(`/leaderboard`);
    },
    initialData: {
      count: 0,
      next: null,
      previous: null,
      results: [
        {
          wallet: "",
          referrals: "",
          promotion_amount: "",
          amount: "",
          participated_promotions: "",
        },
      ],
    },
  });
};
