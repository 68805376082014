import {
  Flex,
  Heading,
  IconButton,
  Stack,
  useColorModeValue,
  VStack,
  Text,
} from "@chakra-ui/react";
import { CreateCampaignFormType } from "features/setUpReferrals/api/type";
import ApprovableTransaction from "features/stakingPage/components/ApprovableTransactionButton";
import { FormikContextType } from "formik";
import { getPromotionContract } from "helpers/networkHelper";
import { FunctionComponent } from "react";
import { HiArrowLeft } from "react-icons/hi2";
import DottedText from "ui/components/DottedText/DottedText";
import { useAccount, useBalance, useNetwork } from "wagmi";

interface CreateFormSubmitProps {
  formik: FormikContextType<CreateCampaignFormType>;
}

const CreateFormSubmit: FunctionComponent<CreateFormSubmitProps> = ({
  formik,
}) => {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const contractAddress = getPromotionContract(chain?.id);

  const { data: foreignTokenBalanceData, error } = useBalance({
    address: address,
    token: formik.values.token?.token_contract as `0x${string}`,
    watch: true,
  });

  return (
    <VStack alignItems={"stretch"}>
      <Flex alignItems={"center"} gap={"1.25em"} mb={"2em"}>
        <IconButton
          opacity={"0.5"}
          aria-label={""}
          icon={<HiArrowLeft fontSize={"1.5em"} />}
          variant={"transparent"}
          minW={0}
          onClick={() => {
            formik.setFieldValue("token", undefined);
          }}
        />
        <Heading fontSize={"2em"} h={"1.2em"}>
          Deposit Rewards
        </Heading>
      </Flex>

      <Stack spacing={"2em"} mb={"2em"}>
        <DottedText
          leftText="Your balance"
          rightText={
            foreignTokenBalanceData?.formatted
              ? parseFloat(foreignTokenBalanceData?.formatted).toFixed(4) +
                " " +
                formik.values.token?.short_name
              : "0" + formik.values.token?.short_name
          }
        />

        <DottedText
          leftText="Total campaign rewards"
          rightText={
            formik.values.tokensDistributedCampaign +
            " " +
            formik.values.token?.short_name
          }
        />
        <DottedText
          leftText="Campaign fee"
          rightText={
            formik.values.tokensDistributedCampaign * 0.1 +
            " " +
            formik.values.token?.short_name
          }
        />
      </Stack>

      <Flex
        justifyContent={"flex-end"}
        py={"1.25em"}
        border={"1px solid"}
        borderColor={useColorModeValue("#E0E0E0", "rgba(255, 255, 255, 0.20)")}
        borderLeft={"none"}
        borderRight={"none"}
        gap={"3em"}
        alignItems={"center"}
        mb={"2em"}
      >
        <Text textTransform={"uppercase"} opacity={"0.5"}>
          Deposit total
        </Text>

        <Text fontWeight={"800"} fontFamily="main" fontSize={"lg"}>
          {(formik.values.tokensDistributedCampaign * 1.1).toFixed(4) +
            " " +
            formik.values.token?.short_name}
        </Text>
      </Flex>

      <ApprovableTransaction
        contractAddress={contractAddress}
        tokenContractAddress={
          formik.values.token?.token_contract as `0x${string}`
        }
        allowanceCondition={(allowance) => {
          return (
            parseFloat(allowance.toString()) * 1 >=
            formik.values.tokensDistributedCampaign *
              Math.pow(10, foreignTokenBalanceData?.decimals!!) *
              1.1
          );
        }}
        callback={() => {
          formik.submitForm();
        }}
        title={"DEPOSIT & CREATE CAMPAIGN"}
        buttonProps={{
          w: "fit-content",
          size: "lg",
          alignSelf: "flex-end",
          isLoading: formik.isSubmitting,
        }}
      />
    </VStack>
  );
};

export default CreateFormSubmit;
