import { FunctionComponent, useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalOverlay,
  useColorModeValue,
  ModalHeader,
  Button,
  Text,
  Flex,
  Center,
} from "@chakra-ui/react";
import { useDisconnect, useNetwork, useSwitchNetwork } from "wagmi";

interface ChangeNetworkModalProps {}

const ChangeNetworkModal: FunctionComponent<ChangeNetworkModalProps> = () => {
  const { disconnect } = useDisconnect();
  const { switchNetwork } = useSwitchNetwork();
  const [isOpen, setIsOpen] = useState(false);
  const { chain } = useNetwork();

  useEffect(() => {
    if (chain) {
      setIsOpen(
        !process.env
          .REACT_APP_SUPPORTED_CHAIN_IDS!.split(" ")
          .includes(chain.id.toString())
      );
    }
  }, [chain]);
  return (
    <>
      <Modal isOpen={isOpen} onClose={() => {}} isCentered size={"2xl"}>
        <ModalOverlay />
        <ModalContent
          overflow={"hidden"}
          bg={useColorModeValue("#FFFFFF", "#1A1A1A")}
        >
          <ModalHeader textAlign={"center"}>
            Switch the network, please
          </ModalHeader>

          <ModalBody>
            <Center flexDirection={"column"} alignContent={"center"}>
              <Text
                fontFamily={"main"}
                fontSize={"0.875rem"}
                fontWeight={"400"}
                opacity={0.5}
                mt={"1em"}
                mb={"1.25em"}
              >
                You need to change the network to continue working.
              </Text>
              <Flex justifyContent={"space-around"} w={"100%"}>
                <Button
                  fontSize={"0.85em"}
                  w={"13em"}
                  onClick={() => {
                    disconnect();
                    setIsOpen(false);
                  }}
                >
                  Disconnect
                </Button>
                <Button
                  onClick={() => {
                    switchNetwork?.(
                      parseFloat(
                        (
                          process.env.REACT_APP_SUPPORTED_CHAIN_IDS ?? "56"
                        ).split(" ")[0]
                      )
                    );
                  }}
                  fontSize={"0.85em"}
                  w={"13em"}
                >
                  Chenge network
                </Button>
              </Flex>
            </Center>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChangeNetworkModal;
