import { Box, Button, StyleProps, Text } from "@chakra-ui/react";
import PromoteModal from "features/promotionalPage/components/PromoteModal";
import { useSetUpReferralsTokens } from "features/setUpReferrals/api/client";
import Token from "features/token/Token";
import { FunctionComponent, useMemo, useState } from "react";
import { PromotionTokenTypes } from "types";
import Pagination from "ui/components/Pagination";
import TableComponent from "ui/components/Table";
import { useAccount, useNetwork } from "wagmi";
import ManageCampaignModal from "../ManageCampaignModal";
import MyCampaignStats from "../MyCampaignStats";
import { getNeworkId } from "helpers/networkHelper";

const headers = [
  {
    name: "referredToken",
    title: "Referred token",
    columnStyle: { textAlign: "start" } as StyleProps,
  },
  {
    name: "total_daily_rewards",
    title: "Total daily rewards",
  },
  {
    name: "total_campaign_rewards",
    title: "Total campaign rewards",
  },
  {
    name: "start_time",
    title: "Start date",
  },
  {
    name: "end_time",
    title: "End date",
  },
  {
    name: "button",
    title: "",
  },
];

interface CompletedSalesTableProps {
  value: any;
}

const CompletedSalesTable: FunctionComponent<CompletedSalesTableProps> = ({
  value,
}) => {
  const { address } = useAccount();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCampaign, setSelectedCampaign] =
    useState<PromotionTokenTypes>();
  const { chain } = useNetwork();
  const networkId = getNeworkId(chain?.id);
  const { data: response } = useSetUpReferralsTokens({
    pageNumber: currentPage,
    promotionTokenNetwork: networkId,
    user: address?.toLocaleLowerCase(),
    value: value,
    status: ["active", "upcoming"],
  });
  
  const rows = useMemo(() => {
    return response.results.map((item) => {
      return {
        referral_rate: (
          <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
            {item.referral_rate + "%"}
          </Text>
        ),
        total_daily_rewards: (
          <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
            {(
              item.total_daily_rewards /
              10 ** item.token_info.decimals
            ).toFixed(4) +
              " " +
              item.token_info.short_name}
          </Text>
        ),
        total_campaign_rewards: (
          <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
            {(
              item.total_campaign_rewards /
              10 ** item.token_info.decimals
            ).toFixed(4) +
              " " +
              item.token_info.short_name}{" "}
          </Text>
        ),
        referredToken: (
          <Token
            imgStyle={{
              w: "2.5em",
              h: "2.5em",
              borderRadius: "3.125em",
            }}
            nameStyle={{ fontSize: "0.875em", fontWeight: "600" }}
            shortNameStyle={{
              fontSize: "0.75em",
              fontWeight: "400",
              opacity: "0.5",
            }}
            img={item.token_info.img_url}
            name={item.token_info.name}
            shortName={item.token_info.short_name}
          />
        ),
        start_time: (
          <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
            {new Date(item.start_time ?? Date.now()).toLocaleDateString(
              "en-GB"
            )}
          </Text>
        ),
        end_time: (
          <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
            {new Date(
              new Date(item.start_time ?? Date.now()).getTime() +
                Math.floor(
                  (item.total_campaign_rewards / item.total_daily_rewards) *
                    24 *
                    60 *
                    60 *
                    1000
                ) -
                86400000
            ).toLocaleDateString("en-GB")}
          </Text>
        ),
        button: (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setSelectedCampaign(item);
            }}
          >
            MANAGE
          </Button>
        ),

        dropDownContent: <MyCampaignStats campaignInfo={item} />,
      };
    });
  }, [response.results, address]);

  return (
    <>
      <Box>
        <Text
          fontFamily="secondary"
          mt={"2.5em"}
          fontSize={{ base: "1em", sm: "1.25em" }}
          fontWeight={"600"}
          mb={"1.25em"}
          textTransform={"uppercase"}
        >
          Crypto Tokens
        </Text>
        <Box overflowX="auto" whiteSpace="nowrap">
          <TableComponent
            headers={headers}
            rows={rows}
            walletConnectText="Connect your wallet to see your referral campaigns"
            emptyRowsHeader="You haven’t created any campaigns yet."
            necessaryConectWallet={true}
          />
        </Box>
        <Pagination
          containerStyle={{
            mt: "1.5em",
          }}
          count={response.count}
          currentPage={currentPage}
          handlePageChange={(pageNumber: number) => {
            setCurrentPage(pageNumber);
          }}
          maxPagesToShow={8}
        />
      </Box>

      <ManageCampaignModal
        isOpen={!!selectedCampaign}
        onClose={() => {
          setSelectedCampaign(undefined);
        }}
        campaignData={selectedCampaign!!}
      />
    </>
  );
};

export default CompletedSalesTable;
