import { FunctionComponent } from "react";
import { Text, Button, StyleProps, Box } from "@chakra-ui/react";
import TableComponent from "ui/components/Table";
import { useLeaderboard } from "./api";
interface LeaderboardPageProps {}
const headers = [
  {
    name: "wallet",
    title: "wallet",
  },
  {
    name: "tokens_purchased",
    title: "Tokens purchased ($)",
  },
  {
    name: "referrals",
    title: "referrals",
  },
  {
    name: "participated_promotions",
    title: "Participated in promotions",
  },
  {
    name: "created_promotions",
    title: "Created promotions",
  },
];

const LeaderboardPage: FunctionComponent<LeaderboardPageProps> = () => {
  const { data: response } = useLeaderboard();

  const rows = response.results.map((item, index) => {
    return {
      wallet: (
        <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
          {item.wallet.slice(0, 7) + "..." + item.wallet.slice(-4)}
        </Text>
      ),
      created_promotions: (
        <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
          {item.promotion_amount}
        </Text>
      ),
      participated_promotions: (
        <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
          {item.participated_promotions}
        </Text>
      ),
      referrals: (
        <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
          {item.referrals}
        </Text>
      ),
      tokens_purchased: (
        <Text fontSize={"0.875em"} fontWeight={"400"} fontFamily={"main"}>
          {`$ ${parseFloat(item.amount).toFixed(2)}`}
        </Text>
      ),
    };
  });

  return (
    <>
      <Text
        fontFamily="secondary"
        fontSize={{ base: "2em", sm: "2.5em" }}
        fontWeight={"600"}
      >
        Leaderboard
      </Text>
      <Text mt={"0.5em"} fontSize={"0.875em"} fontWeight={"400"} opacity={0.5}>
        Welcome to the leaderboard! Showcasing the top participants and their
        achievements
      </Text>
      <Text
        fontFamily="secondary"
        mt={"2.5em"}
        fontSize={{ base: "1em", sm: "1.25em" }}
        fontWeight={"600"}
        mb={"1.25em"}
        textTransform={"uppercase"}
      >
        top users
      </Text>
      <Box overflowX="auto" whiteSpace="nowrap">
        <TableComponent
          headers={headers}
          rows={rows}
          emptyRowsTitle="Sorry, but there are no tokens yet"
          emptyRowsHeader="Please check later."
        />
      </Box>
    </>
  );
};

export default LeaderboardPage;
