import base_logo from "assets/neworkLogo/base_logo_network.svg";
import bnb_logo from "assets/neworkLogo/bnb_logo_network.svg";

export const getImageWithId = (chain: any) => {
  const id = chain?.id;
  let image = base_logo;
  switch (id) {
    case 8453:
      image = base_logo;
      break;
    case 56:
      image = bnb_logo;
      break;
    default:
      image = base_logo;
  }
  return image;
};

export const networksList = [
  {
    name: "BNB Chain",
    image: bnb_logo,
    chainName: "BNB Chain",
    chainId: 56,
    nativeCurrency: {
      name: "BNB",
      decimals: 18,
      symbol: "BNB",
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
  },
  {
    name: "Base",
    image: base_logo,
    chainName: "Base Mainnet",
    chainId: 8453,
    nativeCurrency: {
      name: "ETH",
      decimals: 18,
      symbol: "ETH",
    },
    rpcUrls: ["https://mainnet.base.org"],
  },
];
