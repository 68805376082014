import { Center, Text, Flex, Button } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { Link as ReactRouterLink } from "react-router-dom";

interface NotFoundScreenProps {}

const NotFoundScreen: FunctionComponent<NotFoundScreenProps> = () => {
  return (
    <Flex w={"100%"} h={"100%"} flexDirection={"column"} alignItems={"center"}>
      <Center flexDirection={"column"}>
        <Text
          fontSize={"5em"}
          fontWeight={600}
          textTransform={"uppercase"}
          fontFamily={"secondary"}
        >
          404
        </Text>
        <Text
          mt={"0.25em"}
          fontSize={"1.25em"}
          fontWeight={600}
          textTransform={"uppercase"}
          fontFamily={"secondary"}
        >
          PAGE NOT FOUND
        </Text>
        <Text
          mt={"1.5em"}
          fontSize={"1em"}
          fontWeight={400}
          textTransform={"uppercase"}
          fontFamily={"secondary"}
          textAlign={"center"}
          w={"65%"}
          opacity={"0.5"}
        >
          The page you were looking for could not be found. It Might have been
          removed, renamed or did not exist in the first place.
        </Text>
        <Button mt={"1em"} as={ReactRouterLink} to="/">
          Go to homepage
        </Button>
      </Center>
    </Flex>
  );
};

export default NotFoundScreen;
