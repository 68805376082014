import { useQuery } from "@tanstack/react-query";
import { apiClient } from "services/api/apiClient";
import { PromoteTokensType } from "./type";

interface PaginationPropsType {
  pageNumber?: number;
}

interface PromotionCampaignsType extends PaginationPropsType {
  promotionTokenNetwork: string;
  status?: string | string[];
}

export const usePromoteTokens = ({
  pageNumber,
  promotionTokenNetwork,
  status,
}: PromotionCampaignsType) => {
  return useQuery<PromoteTokensType>({
    queryKey: ["PromoteTokens", pageNumber, status, promotionTokenNetwork],
    queryFn: () => {
      return apiClient.get(
        `/promotions/?network_id=${promotionTokenNetwork}&page=${pageNumber}${
          typeof status === "string" ? "&status=" + status : ""
        }`
      );
    },
    initialData: {
      count: 0,
      next: null,
      previous: null,
      results: [
        {
          id: "",
          total_daily_rewards: 0,
          total_campaign_rewards: 0,
          referral_rate: 0,
          token: "",
          user: "",
          time_end: "",
          start_time: "",
          status: "",
          network_id: "",
          token_info: {
            name: "",
            short_name: "",
            img_url: "",
            decimals: 18,
            token_contract: "",
            network: "",
          },
        },
      ],
    },
    select: (data) => {
      //TODO: Remove this once backend filtering works for multiple status options
      if (Array.isArray(status)) {
        return {
          ...data,
          results: data.results.filter((e) => status.includes(e.status)),
        };
      }

      return data;
    },
  });
};
