import React from "react";
import { Flex, StyleProps, Text, useColorModeValue } from "@chakra-ui/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

interface PaginationProps {
  itemPerPage?: number;
  count: number;
  maxPagesToShow: number;
  currentPage: number;
  handlePageChange: Function;
  containerStyle: StyleProps;
}

const Pagination: React.FC<PaginationProps> = ({
  count,
  maxPagesToShow = 8,
  currentPage,
  handlePageChange,
  containerStyle,
  itemPerPage = 10,
}) => {
  let numberOfPages = count / itemPerPage;
  numberOfPages = numberOfPages >= 1 ? numberOfPages : numberOfPages;

  const shouldShowDotsBefore = currentPage > Math.floor(maxPagesToShow / 2) + 1;
  const shouldShowDotsAfter =
    currentPage < numberOfPages - Math.floor(maxPagesToShow / 2);

  const borderColor = useColorModeValue("#000", "#FFFFFF");
  return numberOfPages > 1 ? (
    <Flex justifyContent={"space-around"} gap={'2em'} {...containerStyle} >
      <Flex
        alignItems={"center"}
        onClick={
          currentPage === 1
            ? () => {}
            : () => {
                handlePageChange(currentPage - 1);
              }
        }
        cursor={currentPage === 1 ? "not-allowed" : "pointer"}
        opacity={currentPage === 1 ? 0.5 : 1}
      >
        <ArrowLeftIcon width={"1.25em"} height={"1.25ems"}></ArrowLeftIcon>
        <Text
          mt={"0.1em"}
          ml={"0.5em"}
          fontSize={"0.875em"}
          fontFamily={"secondary"}
          fontWeight={"500"}
        >
          PREV
        </Text>
      </Flex>
      <Flex gap={"1.25em"}>
        {Array.from({ length: numberOfPages }, (_, index) => index + 1)
          .filter(
            (pageNumber) =>
              pageNumber === 1 ||
              pageNumber === numberOfPages ||
              (pageNumber >= currentPage - Math.floor(maxPagesToShow / 2) &&
                pageNumber <= currentPage + Math.floor(maxPagesToShow / 2))
          )
          .map((pageNumber, index, array) => (
            <Flex
              key={pageNumber}
              fontWeight={"600"}
              fontFamily={"main"}
              fontSize={"0.875em"}
            >
              {shouldShowDotsBefore && index === 1 && (
                <Flex mr="1.25em">. . .</Flex>
              )}
              <Flex
                onClick={() => handlePageChange(pageNumber)}
                cursor={pageNumber === currentPage ? "not-allowed" : "pointer"}
                opacity={pageNumber === currentPage ? 1 : 0.5}
                borderColor={borderColor}
                borderWidth={pageNumber === currentPage ? "2px" : "0px"}
                borderRadius={"100em"}
                w="2em"
                h="2em"
                justifyContent={"center"}
                alignItems={"center"}
              >
                {pageNumber}
              </Flex>
              {shouldShowDotsAfter && index === array.length - 2 && (
                <Flex ml="1.25em">. . .</Flex>
              )}
            </Flex>
          ))}
      </Flex>
      <Flex
        cursor={currentPage === numberOfPages ? "not-allowed" : "pointer"}
        opacity={currentPage === numberOfPages ? 0.5 : 1}
        alignItems={"center"}
        onClick={
          currentPage === numberOfPages
            ? () => {}
            : () => handlePageChange(currentPage + 1)
        }
      >
        <Text
          mt={"0.1em"}
          mr={"0.5em"}
          fontSize={"0.875em"}
          fontFamily={"secondary"}
          fontWeight={"500"}
        >
          NEXT
        </Text>
        <ArrowRightIcon width={"1.25em"} height={"1.25em"} />
      </Flex>
    </Flex>
  ) : null;
};

export default Pagination;
