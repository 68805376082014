import { apiClient } from "services/api/apiClient";

export const createPromotion = (data: any) => {
  return apiClient.post(`/create-promotion/`, {
    ...data,
  });
};
export const getNewPromotionId = (wallet: string, token: string) => {
  return apiClient.get(
    `/get-new-promotion-id/?wallet=${wallet}&token_address=${token}`
  );
};
